import * as React from 'react';
import { Component, Fragment, Props, SFC } from 'react';

import * as Actions from 'client/redux/Actions';
import * as Types from 'client/Types';
import * as Utils from 'util/Utils';
import * as Api from 'client/Api';

/*
TODO:
*/

interface ResetRequestProps {
}
interface ResetRequestState {
  requestCompleted : boolean,
  email : string,
  error : string
}
class ResetRequest extends Component<ResetRequestProps, ResetRequestState> {
  state : ResetRequestState = { // Adding this sig gives more informative errors
    requestCompleted: false,
    email: '',
    error: ''
  };

  handleSetEmail = (evt : React.ChangeEvent<HTMLInputElement>) => {
    this.setState({email: evt.currentTarget.value});
  }

  requestReset = async (evt : React.SyntheticEvent<any>) => {
    try {
      await Api.requestReset(this.state.email);
      this.setState({requestCompleted: true});
    } catch (err) {
      this.setState({error: '' + err});
    }
  }

  isValid = () => {
    return this.state.email.match(/.+\@.+\..+/);
  }

  render() {
    return (
      <div className='reset-request simple-page'>
        { !this.state.requestCompleted
          ? <div>
              <p>Vul hieronder het e-mailadres in waarmee je je account aangemaakt hebt:
              </p>
              <form name='resetRequestForm'>
                <div className='form-group'>
                  <input type='email' required className='input-sm form-control'
                         value={this.state.email} onChange={this.handleSetEmail}/>
                </div>
                <div className='request-error'>{this.state.error}</div>
                <div className='form-group'>
                  <input type='button' className='btn btn-sm btn-default' value='Vraag wachtwoordreset aan'
                         onClick={this.requestReset}
                         disabled={!this.isValid()}/>
                </div>
              </form>
            </div>
         :  <div>
              Er is een e-mail verstuurd naar {this.state.email} met instructies om je wachtwoord te resetten.
            </div> }
      </div>
    );
  }
}

export default ResetRequest;
