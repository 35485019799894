import * as React from 'react';
import { Component, Fragment, Props } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';

import * as Types from 'client/Types';
import * as Actions from 'client/redux/Actions';

/*
TODO:
- Change links to react-router
 */

interface HomeProps {
  authUser : Types.Professional | null,
  nrOfProfessionals : number,
  nrOfReviews : number,
  dispatch : Actions.CombinedDispatch
}
class Home extends Component<HomeProps, {}> {
  refreshStats = () => {
    this.props.dispatch(Actions.refreshStats());
  }

  componentDidMount() {
    this.refreshStats();
  }
  render() {
    const isAuth = this.props.authUser !== null;

    return (
      <div className='home'>
        <div className='banner'>

          { !isAuth &&
          <div className='container hidden-xs'>
            <div className='row'>
              <div className='col-md-1'></div>
              <div className='col-md-10'>
                <Link className='call-to-action' to='/registration/register'>Maak een profiel aan</Link>
              </div>
            </div>
          </div> }
          <div className='banner-inside'>
            <div className='banner-text'>
              <div className='title'>Interimreview.nl</div>
              <ul className='selling-points'>
                <li className='selling-point'>Word direct gevonden door opdrachtgevers</li>
                <li className='selling-point'>Vind direct een goede interim professional</li>
                <li className='selling-point'>Geef en verzamel waardevolle feedback</li>
                <li className='selling-point'>Gratis</li>
              </ul>
            </div>
          </div>
        </div>

        <div className='container'>
          <div className='row'>
            <div className='col-md-8 col-md-offset-2'>

              <div className='steps'>
                { isAuth ? <div className='flex-h-spacer'></div> :
                  <Fragment>
                    <div className='step'>
                      <Link to='/registration/register'>Maak een profiel aan</Link>
                    </div>
                    <div className='flex-h-spacer'></div>
                  </Fragment> }
                <div className='step'>
                  <Link to='/professionals?q='>Schrijf een review</Link>
                </div>
                <div className='flex-h-spacer'></div>
                <div className='step'>
                  <Link to='/professionals?q='>Zoek een professional</Link>
                </div>
                { isAuth && <div className='flex-h-spacer'></div> }
              </div>

              <div className='description'>
                Ben je op zoek naar een goede professional? Wil je direct gevonden worden door opdrachtgevers? Dit is
                Interimreview.nl. Mijn naam is <Link to='/professional/p-2'>Lin Le Roux</Link>, ik ben interim
                professional en ik heb Interimreview.nl opgericht. Zelf ben ik graag direct vindbaar voor
                opdrachtgevers. Ik geloof dat de interim markt veel beter kan, door kwaliteit onderscheidend te maken
                met behulp van eerlijke reviews. En professionals zijn hier direct vindbaar. Bouw mee aan dit platform
                door <Link to='/registration/register'>een profiel aan te maken</Link> of <Link to='/professionals?q='>
                een review te schrijven</Link>. Wil je meer weten? Neem gerust{' '}
                <Link to='/info/contact'>contact</Link> met mij op.
              </div>

              <div className='stats-wrapper'>
                <span className='stats'>
                  <Link className='stat' to='/professionals?q='>{this.props.nrOfProfessionals}</Link>
                  <Link className='stat-label' to='/professionals?q='>professionals</Link>
                  <span className='separator'></span>
                  <span className='stat'>{this.props.nrOfReviews}</span><span className='stat-label'>reviews</span>
                </span>
              </div>

              <div className='remarks'>
                <img className='logo' src='/img/logo-50x50.png'/>
                <div className='remark'>
                  Een transparante markt<br/>
                  stimuleert kwaliteit
                </div>
                <div className='flex-h-spacer'></div>
                <img className='logo' src='/img/logo-50x50.png'/>
                <div className='remark'>
                  Interimreview.nl<br/>
                  is onafhankelijk
                </div>
                <div className='flex-h-spacer'></div>
                <img className='logo' src='/img/logo-50x50.png'/>
                <div className='remark'>
                  De beste reviews zijn<br/>
                  eerlijk en genuanceerd
                </div>
              </div>
            </div>

          </div>

        </div>
      </div>
    );
  }
}

const mapStateToProps = ({global: {nrOfProfessionals, nrOfReviews}, auth: {authUser}} : Types.ReducerState) => ({
  authUser,
  nrOfProfessionals,
  nrOfReviews
});

export default connect(mapStateToProps)(Home);
