import * as React from 'react';
import { Link } from 'react-router-dom';

const Footer : React.SFC<{}> = () => (
  <footer className='body-footer'>
    <div className='footer-info container-fluid'>
      <div className='row'>
        <div className='test col-sm-offset-3 col-sm-3'>
          <div className='subject'>Over Interimreview.nl</div>
          <div className='items'>
            <div className='item'><Link to='/info/about'>Wat is Interimreview.nl</Link></div>
            <div className='item'><Link to='/info/terms'>Algemene voorwaarden</Link></div>
            <div className='item'><Link to='/info/contact'>Contact</Link></div>
          </div>
        </div>
        <div className='col-sm-offset-1 col-sm-3'>
          <div className='subject'>Informatie</div>
          <div className='items'>
            <div className='item'><Link to='/info/professional'>Voor professionals</Link></div>
            <div className='item'><Link to='/info/reviewer'>Voor reviewers</Link></div>
          </div>
        </div>
      </div>
    </div>
    <div className='footer-bar container-fluid'>
      <div className='row'>
        <div className='copyright-col col-sm-6'>
          <div className='flex-h-spacer visible-xs'></div>
          <div className='copyright'>&copy; 2018 <a href='http://www.veranderkracht.nl'>Lin Le Roux</a></div>
          <div className='flex-h-spacer visible-xs'></div>
        </div>
        <div className='implementation-col col-sm-6'>
          <div className='flex-h-spacer'></div>
          <div className='implementation'>Website &amp; design <a href='http://www.oblomov.com'>Oblomov Systems</a></div>
          <div className='flex-h-spacer visible-xs'></div>
        </div>
      </div>
    </div>
  </footer>
);

export default Footer;

