import { ProfQueryResult } from './../BasicTypes';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import * as Api from 'client/Api';

import { ModalVisibility, ReducerState } from './ReduxTypes';
import * as Types from 'client/Types';

interface SetLoginModalVisibilityAction { type : 'SET_LOGIN_MODAL_VISIBILITY', isVisible : Types.ModalVisibility }
interface SetLoginModalUsernameAction { type : 'SET_LOGIN_MODAL_USERNAME', username : string }
interface SetConfirmModalVisibilityAction { type : 'SET_CONFIRM_MODAL_VISIBILITY', isVisible : Types.ModalVisibility }
interface InitConfirmModalAction {
  type : 'INIT_CONFIRM_MODAL',
  title : string, message : string,
  acceptText : string, cancelText : string,
  isVisible : Types.ModalVisibility,
  handleAccept : () => void, handleCancel : () => void
}

interface SetStatsAction { type : 'SET_STATS', nrOfProfessionals : number, nrOfReviews : number }

interface SetAuthenticatedUserAction { type : 'SET_AUTHENTICATED_USER', authUser : Types.Professional | null }

interface ReceiveProfessionalsAction {
  type : 'RECEIVE_PROFESSIONALS',
  queriedString : string,
  queriedOffset : number,
  queriedLimit : number,
  professionals : Types.Professional[],
  pageIndex : number,
  pagesTotal : number,
  resultsTotal : number,
  pageLinks : Types.PageLinks,
  receivedAt : Date
}
interface ReceiveProfessionalAction { type : 'RECEIVE_PROFESSIONAL', professional : Types.Professional, receivedAt : Date }
interface RemoveProfessionalAction { type : 'REMOVE_PROFESSIONAL', userId : string }

interface ReceiveReviewsAction { type : 'RECEIVE_REVIEWS', userId : string, reviews : Types.Review[], receivedAt : Date }
interface RemoveReviewAction { type : 'REMOVE_REVIEW', reviewId : string }

// interface NotifyAction { type : 'NOTIFY', notification : Notification }
// interface ClearNotificationsAction { type : 'CLEAR_NOTIFICATIONS' }

export type ModalAction = SetLoginModalVisibilityAction | SetLoginModalUsernameAction
                        | InitConfirmModalAction | SetConfirmModalVisibilityAction;

export type GlobalAction = SetStatsAction;
export type AuthAction = SetAuthenticatedUserAction;
export type ProfessionalsAction = ReceiveProfessionalsAction | ReceiveProfessionalAction
                                | RemoveProfessionalAction;
export type ReviewsAction = ReceiveReviewsAction | RemoveReviewAction;
// export type NotifierAction = NotifyAction | ClearNotificationsAction;

export type RootAction = ModalAction | GlobalAction | AuthAction | ProfessionalsAction | ReviewsAction;

export type CombinedDispatch = ThunkDispatch<ReducerState, void, RootAction>; // Partially parameterize ThunkDispatch

type ReviewThunkAction<R> = ThunkAction<R, ReducerState, void, RootAction>; // Partially parameterize ThunkAction

// // Basic action creators

export const setLoginModalVisibility = (isVisible : Types.ModalVisibility) : ModalAction =>
  ({type: 'SET_LOGIN_MODAL_VISIBILITY', isVisible});

export const setLoginModalUsername = (username : string) : ModalAction =>
  ({type: 'SET_LOGIN_MODAL_USERNAME', username});

export const setConfirmModalVisibility = (isVisible : Types.ModalVisibility) : ModalAction =>
  ({type: 'SET_CONFIRM_MODAL_VISIBILITY', isVisible});

const initConfirmModal =
  (title : string, message : string, acceptText : string, cancelText : string,
   handleAccept : () => void, handleCancel : () => void, isVisible : Types.ModalVisibility) : ModalAction =>
  ({type: 'INIT_CONFIRM_MODAL', title, message, acceptText, cancelText, handleAccept, handleCancel, isVisible});

const setStats = (nrOfProfessionals : number, nrOfReviews : number) : GlobalAction =>
  ({type: 'SET_STATS', nrOfProfessionals, nrOfReviews});


const setAuthenticatedUser = (authUser : Types.Professional |  null) : AuthAction =>
  ({type: 'SET_AUTHENTICATED_USER', authUser});


const receiveProfessionals : (queriedString : string, queriedOffset : number, queriedLimit : number,
                                     professionals : Types.Professional[],
                                    pageIndex : number, pagesTotal : number, resultsTotal : number,
                                     pageLinks : Types.PageLinks,
                                    ) => ProfessionalsAction =
               (queriedString, queriedOffset, queriedLimit, professionals, pageIndex, pagesTotal, resultsTotal, pageLinks) => ({
  type: 'RECEIVE_PROFESSIONALS',
  queriedString,
  queriedOffset,
  queriedLimit,
  professionals,
  pageIndex,
  pagesTotal,
  pageLinks,
  resultsTotal,
  receivedAt: new Date()
});

export const receiveProfessional : (professional : Types.Professional) => ProfessionalsAction = (professional) => ({
  type: 'RECEIVE_PROFESSIONAL',
  professional,
  receivedAt: new Date()
});

const removeProfessional : (userId : string) => ProfessionalsAction = (userId) => ({
  type: 'REMOVE_PROFESSIONAL',
  userId
});

export const receiveReviews : (userId : string, reviews : Types.Review[]) => ReviewsAction = (userId, reviews) => ({
  type: 'RECEIVE_REVIEWS',
  userId,
  reviews,
  receivedAt: new Date()
});

const removeReview : (reviewId : string) => ReviewsAction = (reviewId) => ({
  type: 'REMOVE_REVIEW',
  reviewId
});

// const notify : (type : NotificationType, msg : string) => NotifierAction = (type, msg) => ({
//   type: 'NOTIFY',
//   notification: {type: type, message: msg, timestamp: new Date()}
// });
// export const notifyError : (msg : string) => NotifierAction = (msg) => notify('Error', msg);
// export const notifyWarning : (msg : string) => NotifierAction = (msg) => notify('Warning', msg);
// export const notifyInfo : (msg : string) => NotifierAction = (msg) => notify('Info', msg);

// export const clearNotifications : () => NotifierAction = () => ({
//   type: 'CLEAR_NOTIFICATIONS'
// });


// // Thunk actions

export const showLoginModal : (username? : string) => ReviewThunkAction<Promise<void>> =
                        (username? : string) => async (dispatch, getState) => {
  dispatch(setLoginModalUsername(username ? username : ''));
  dispatch(setLoginModalVisibility(Types.ModalVisibility.Show));
};

export const showDialog : (title : string, message : string,
                           acceptText : string, cancelText : string,
                           handleAccept : () => void, handleCancel : () => void) =>
                          ReviewThunkAction<Promise<{}>> =
                          (title, message, acceptText, cancelText, handleAccept, handleCancel) =>
                          (dispatch) => {
  return new Promise((resolve, reject) => {
    dispatch(initConfirmModal(title, message, acceptText, cancelText, handleAccept, handleCancel, Types.ModalVisibility.Show));
  });
};

export const refreshStats : () => ReviewThunkAction<Promise<{nrOfProfessionals : number, nrOfReviews : number}>> =
                        () => async (dispatch, getState) => {
  const {nrOfProfessionals, nrOfReviews} = await Api.getStats();
  dispatch(setStats(nrOfProfessionals, nrOfReviews));
  return {nrOfProfessionals, nrOfReviews};
};

export const login : (username : string, password : string) => ReviewThunkAction<Promise<Types.Professional | null>> =
                        (username : string, password : string) => async (dispatch, getState) => {
  const authUser = await Api.loginUser(username, password);
  dispatch(setAuthenticatedUser(authUser));
  return authUser;
};

export const logout : () => ReviewThunkAction<Promise<void>> =
                        () => async (dispatch, getState) => {
  await Api.logoutUser(); // TODO: can this fail?
  dispatch(setAuthenticatedUser(null));
};

export const syncAuth : () => ReviewThunkAction<Promise<Types.Professional | null>> =
                        () => async (dispatch, getState) => {
  const authUser = await Api.getAuthUser();
  // console.log('dispatching syncAuth', setAuthenticatedUser(authUser));
  dispatch(setAuthenticatedUser(authUser));
  return authUser;
};

export const fetchProfessionals : (queryString : string, offset : number | null, limit : number | null) =>
               ReviewThunkAction<Promise<Types.ProfQueryResult>> =
               (queryString, queryOffset, queryLimit) => async (dispatch, getState) => {
  const queryResult = await Api.getProfessionals(queryString, queryOffset, queryLimit);
  // console.log(`fetched ${queryResult.results.length} profs for query '${query}'`);
  const {
    offset: queriedOffset, limit: queriedLimit, results,
    pageIndex, pagesTotal, resultsTotal, previousPageLink, numberedPageLinks, nextPageLink} = queryResult;
  const pageLinks = {previousPageLink, numberedPageLinks, nextPageLink};
  // queriedOffset and queriedLimit are taken from response, not parameters queryOffset & queryLimit, so we get the
  // actual & sanitized values used to produce the results.
  dispatch(receiveProfessionals(queryString, queriedOffset, queriedLimit, results, pageIndex, pagesTotal, resultsTotal, pageLinks));
  return queryResult;
};

// Fetches professional without updating queriedProfIds
export const fetchProfessional : (userId : string) => ReviewThunkAction<Promise<Types.Professional>> =
                        (userId) => async (dispatch, getState) => {
  const prof = await Api.getProfessional(userId);
  dispatch(receiveProfessional(prof));
  return prof;
};

export const deleteProfessional : (userId : string) => ReviewThunkAction<Promise<void>> =
                        (userId) => async (dispatch, getState) => {
  await Api.deleteProfessional(userId);
  dispatch(removeProfessional(userId));
};

export const updateProfessional : (prof : Types.Professional) => ReviewThunkAction<Promise<void>> =
                        (prof) => async (dispatch, getState) => {
  dispatch(receiveProfessional(prof)); // pre-emptively update professional in redux store
  await Api.putProfessional(prof);
  dispatch(fetchProfessional(prof.userId));
};

export const registerProfessional : (prof : Types.BaseProfessional, password : string) => ReviewThunkAction<Promise<void>> =
                        (prof, password) => async (dispatch, getState) => {
  await Api.postProfessional(prof, password);
};

export const validateUserId : (userId : string, validationCode : string) => ReviewThunkAction<Promise<Types.Professional>> =
                        (userId, validationCode) => async (dispatch, getState) => {
  return Api.validateUserId(userId, validationCode);
};

export const fetchReviews : (userId : string) => ReviewThunkAction<Promise<Types.Review[]>> =
                        (userId) => async (dispatch, getState) => {
  const reviews = await Api.getReviews(userId);
  dispatch(receiveReviews(userId, reviews));
  return reviews;
};

export const deleteReview : (reviewId : string) => ReviewThunkAction<Promise<void>> =
                        (reviewId) => async (dispatch, getState) => {
  await Api.deleteReview(reviewId);
  dispatch(removeReview(reviewId));
};

export const validateReviewId : (reviewId : string, validationCode : string) => ReviewThunkAction<Promise<Types.Review>> =
                        (reviewId, validationCode) => async (dispatch, getState) => {
  return Api.validateReviewId(reviewId, validationCode);
};

export const acceptReviewId : (reviewId : string, revieweeComment : string) => ReviewThunkAction<Promise<Types.Review>> =
                        (reviewId, revieweeComment) => async (dispatch, getState) => {
  const review = await Api.acceptReviewId(reviewId, revieweeComment);
  const reviews = await Api.getReviews(review.revieweeId);
  dispatch(receiveReviews(review.revieweeId, reviews));
  return review;
};
