import * as React from 'react';
import { Component, SFC } from 'react';
import * as ReactDOM from 'react-dom';
// import { hot } as ReactHotLoader from 'react-hot-loader';
// Should appear before any component declarations, but no need to import before 'react' (unlike error message in
// console suggests.) TODO: install @types/react-hot-loader and use above import when support for {hot} is added.
import * as ReactHotLoader from 'react-hot-loader';
const hot = (ReactHotLoader as any).hot;

import { App } from './App';

import 'html5-boilerplate/dist/css/normalize.css'; //
import 'html5-boilerplate/dist/css/main.css';      // Don't seem to be used, but might be necessary for legacy browsers
import '../../sass/main.scss';

export const HotApp = hot(module)(App);

ReactDOM.render(<HotApp/>, document.getElementById('app'));

// TODO: upgrade to newer hot reload method from 'react-hot-reloader'.
//       First attempt seems to do a full refresh on every first hot reload, which probably means something is not okay.
// Hot Module Replacement API
// if ((module as any).hot) {
//   (module as any).hot.accept(); // this simpler code seems to work as well now
//   // (module as any).hot.accept('./App', () => {
//   //   render(App); // requires function render, see git history
//   // });
//   // Some setups have
//   // import {AppContainer} from 'react-hot-loader'
//   // and surround the root component with it: https://github.com/gaearon/react-hot-boilerplate/blob/next/src/index.js
//   // Everything seems to work just fine without it though.
// }
