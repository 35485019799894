import * as React from 'react';
import { Component, SFC } from 'react';

import { buildInfo, BuildInfo } from 'util/BuildInfo';

type BuildType = ('dev-server' | 'app-bundle' | 'production');

function formatBuildInfo({buildTime, gitInfo : {sha, branch, commitMsg}} : BuildInfo, buildType : BuildType) {
  const buildTimeStr = buildType === 'dev-server' ? buildTime : '<no build time>';
      // Only set buildTime for dev-server build, as for bundle server, pre-rendered BuildInfoRibbon will contain
      // server-bundle buildTime, whereas client BuildInfoRibbon will contain client-bundle build time, causing react
      // pre-rendering mismatch.
  return (buildType === 'dev-server' ? 'Dev-server launch' : 'Build') + ' time: ' + buildTimeStr + '\n' +
         (branch && sha ? 'Branch \'' + branch + '\' [' + sha + ']:\n' : '') +
         'Commit message:\n' + commitMsg;
}


const BuildTag : SFC<{buildType : BuildType}> = (props) => (
    <div className='build-info-ribbon' title={formatBuildInfo(buildInfo, props.buildType)}>
      <div className='build-info-ribbon-content' data-build-type={props.buildType}>
        {props.buildType}
      </div>
    </div>
);

const BuildInfoRibbon : SFC<{}> = () => (
  buildInfo.isProduction ? <div></div> :
    ( buildInfo.isDevServer
    ? <BuildTag buildType='dev-server'/>
    : <BuildTag buildType='app-bundle'/> )
);

export default BuildInfoRibbon;
