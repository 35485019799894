import * as React from 'react';
import { Component, Fragment, Props, SFC } from 'react';

import * as Types from 'client/Types';

const WideDescription : SFC<{
        prof : Types.Professional,
        editedProf : Types.EditedProfessional | null,
        isEditing : boolean,
        updateEditedProfHandler : (key : string) => (evt : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => void
      }> = ({prof, editedProf, isEditing, updateEditedProfHandler}) => (
  <div className='wide-description' data-is-editing={isEditing ? true : undefined}>
    { !isEditing
    ? <WideDescriptionView prof={prof}/>
    : ( editedProf !== null &&
        <WideDescriptionEdit editedProf={editedProf} updateEditedProfHandler={updateEditedProfHandler}/> ) }
  </div>
);

const WideDescriptionView : SFC<{prof : Types.Professional}> = ({prof}) => (!prof.experienceSummary ? null :
  <div>
    <div className='most-recent-job-label'>Samenvatting ervaring:</div>
    <div className='most-recent-job'>{prof.experienceSummary}</div>
  </div>
);

export const WideDescriptionEdit : SFC<{
        editedProf : Types.EditedProfessional,
        updateEditedProfHandler : (key : string) => (evt : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => void
      }> = ({editedProf, updateEditedProfHandler}) => (
  <Fragment>
    <table className='property-list'>
      <tbody>
        <tr>
          <td className='prop-label'>Keywords</td>
          <td className='prop-value'>
            <input type='text' className='input-sm form-control' value={editedProf.keywords}
                    onChange={updateEditedProfHandler('keywords')}
                    placeholder='Kommagescheiden lijst van trefwoorden (optioneel)'/>
          </td>
        </tr>
        <tr>
          <td className='prop-label'>LinkedIn</td>
          <td className='prop-value'>
            <input type='text' className='input-sm form-control' value={editedProf.linkedin}
                    onChange={updateEditedProfHandler('linkedin')}
                    placeholder='(optioneel)'/></td>
        </tr>
        <tr>
          <td className='prop-label'>Website</td>
          <td className='prop-value'>
            <input type='text' className='input-sm form-control' value={editedProf.website}
                    onChange={updateEditedProfHandler('website')}
                    placeholder='(optioneel)'/></td>
        </tr>
      </tbody>
    </table>
    <div className='most-recent-job-label'>Samenvatting ervaring:</div>
    <textarea className='most-recent-job form-control' value={editedProf.experienceSummary}
              onChange={updateEditedProfHandler('experienceSummary')}
              placeholder='Beschrijving opdracht of ervaring relevant voor reviews (optioneel)'></textarea>
  </Fragment>
);

export default WideDescription;
