import {applyMiddleware, compose, createStore, Middleware} from 'redux';
import thunk, { ThunkMiddleware } from 'redux-thunk';

import { ReducerState } from './ReduxTypes';
import reducer from './Reducers';
import * as Actions from 'client/redux/Actions';

// Enable use of Redux DevTools browser extension (and cast the type to the type of `compose`)
const composeEnhancers : typeof compose = (<any>window).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const thunkMiddleware : ThunkMiddleware<ReducerState, Actions.RootAction, void> = thunk;

export const store = createStore(
  reducer,
  composeEnhancers(applyMiddleware(thunkMiddleware))
);
/* middlewares are composed right-associatively wrt dispatch:
   applyMiddleware(mw1, mw2, .., mwn) leads to the following dispatch
   dispatch = mw1(mw2(.. mwn(store.dispatch)..))
   but the action is chained: mw(next) => (action) => {performMiddleWare; next(action)})
   so it is processed first by the first middleware.
   The chained dispatch is also passed to all middlewares, so subsequent dispatches pass through all middlewares as
   well.
*/

// Sync currently authenticated user with server
store.dispatch(Actions.syncAuth());
