import _compact from 'lodash-es/compact';
import _find from 'lodash-es/find';

import * as Types from 'client/Types';

export const showDate = (dateStr : string) => { // 'dd-MM-yyyy, HH:mm';
  const date = new Date(dateStr);
  return ('' + date.getDate()).padStart(2, '0') + '-' + ('' + date.getMonth()).padStart(2, '0') + '-' +
         ('' + date.getFullYear()).padStart(2, '0') + ' ' +
         ('' + date.getHours()).padStart(2, '0') + ':' + ('' + date.getMinutes()).padStart(2, '0');
};

// Convert object with boolean properties to className string.
// E.g. objectToClassName({c1: true, c2: false, c3: true}) === 'c1 c3'
export const objectToClassName : (obj : Record<string, boolean>) => string =
  (obj) => Object.keys(obj).filter((k) => obj[k]).join(' ');

export const showGrade = (grade : number | undefined) =>
  grade ? (grade + 0.0001).toFixed(1) : '-';


// For each userId in maybeUserIds, look up professional in professionalsById, preserving the order in maybeUserIds.
// Length of returned array may be less than maybeUserIds.length, as nulls in maybeUserIds and professionals not in
// professionalsById are dropped.
// OPT: function is O(|maybeUserIds|*|professionalsById|), but these will typically be small
//      if necessary, we can use object representation for professionalsById (eg, prof = professionalsById[userId])
export const getProfessionalsForIds :
               (professionalsById : {id : string, prof : Types.Professional}[], maybeUserIds : (string | null)[]) =>
               Types.Professional[] =
               (professionalsById, maybeUserIds) => {
  return _compact(
    _compact(maybeUserIds).map((uid) => getProfessionalForId(professionalsById, uid))
  );
};

export const getProfessionalForId : (professionalsById : {id : string, prof : Types.Professional}[], userId : string) =>
               Types.Professional | null =
               (professionalsById, userId) => {
  const idProf = _find(professionalsById, ({id}) => id === userId);
  return idProf ? idProf.prof : null;
};
