import * as React from 'react';
import { Component, Fragment, Props, SFC } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as qs from 'query-string';

import * as Actions from 'client/redux/Actions';
import * as Types from 'client/Types';
import * as Utils from 'util/Utils';
import * as Api from 'client/Api';

/*
TODO:
*/

interface ResetPasswordProps extends RouteComponentProps<{}> { // declares match, location, etc. props ({} is url params type)
  dispatch : Actions.CombinedDispatch
}
interface ResetPasswordState {
  userId : string,
  validationCode : string,
  requestCompleted : boolean,
  password : string,
  prof : Types.Professional | null,
  error : string
}
class ResetPassword extends Component<ResetPasswordProps, ResetPasswordState> {
  state : ResetPasswordState = { // Adding this sig gives more informative errors
    userId: '',
    validationCode: '',
    requestCompleted: false,
    password: '',
    prof: null,
    error: ''
  };

  // TODO: migrate to getDerivedStateFromProps in react@16.3
  setStateFromQueryParamString(queryParamStr : string) {
    const {uid: userId, code: validationCode} = qs.parse(queryParamStr);
    // url parameters are ../reset?uid=..&code=.. for setting new password (link from e-mail)
    if (userId && typeof userId === 'string' && validationCode && typeof validationCode === 'string') {
      this.setState({userId, validationCode});
    } else {
      this.setState({
        requestCompleted: true,
        error: 'Url heeft incorrect formaat.'
      });
    }
  }

  componentDidMount() {
    this.setStateFromQueryParamString(this.props.location.search);
  }

  componentWillReceiveProps(nextProps : ResetPasswordProps) {
    if (this.props.location.search !== nextProps.location.search) {
      this.setStateFromQueryParamString(nextProps.location.search);
    }
  }

  handleSetPassword = (evt : React.ChangeEvent<HTMLInputElement>) => {
    this.setState({password: evt.currentTarget.value});
  }

  resetPassword = async (evt : React.SyntheticEvent<any>) => {
    try {
      const prof = await Api.resetPassword(this.state.userId, this.state.validationCode, this.state.password);
      this.setState({requestCompleted: true, prof});
    } catch (err) {
      this.setState({requestCompleted: true, error: '' + err});
    }
  }

  isValid = () => {
    return this.state.password !== '';
  }

  render() {
    const prof = this.state.prof;

    return (
      <div className='reset simple-page'>
        { !this.state.requestCompleted
          ? <div>
              <p>Vul hieronder een nieuw wachtwoord in.</p>
              <form name='resetForm'>
                <div className='form-group'>
                  <input type='password' className='input-sm form-control'
                                         autoCorrect='off' autoCapitalize='off'
                                         autoComplete='current-password'
                                         value={this.state.password} onChange={this.handleSetPassword}
                                         placeholder='Nieuw wachtwoord'/>
                </div>
                <div className='form-group'>
                  <input type='button' className='btn btn-sm btn-default' value='Wachtwoord instellen'
                         onClick={this.resetPassword}
                         disabled={!this.isValid()}/>
                </div>
              </form>
            </div>
          : <div>
              { prof !== null
                ? <div>
                    <p>Het wachtwoord voor {prof.username} is opnieuw ingesteld.</p>
                    <div className='form-group'>
                      <input type='button' className='btn btn-sm btn-default' value='Login'
                             onClick={() => this.props.dispatch(Actions.showLoginModal(prof.username))}/>
                    </div>
                  </div>
                : <div>
                    <p>Er is een fout opgetreden bij het resetten van het wachtwoord:
                    </p>
                    <div className='reset-error'>{this.state.error}</div>
                    <div className='form-group'>
                      <Link to='/registration/reset-request'>
                        <input type='button' className='btn btn-sm btn-default'
                               value='Wachtwoordreset opnieuw aanvragen'/>
                      </Link>
                    </div>
                  </div> }
            </div> }
      </div>
    );
  }
}

const mapStateToProps = ({} : Types.ReducerState) => ({
});

export default connect(mapStateToProps)(ResetPassword);
