import * as React from 'react';
import { Link } from 'react-router-dom';

const Terms : React.SFC<{}> = () => (
  <div className='info'>
    <h2>Algemene voorwaarden</h2>

    <ul>
      <li>Wij verkopen geen informatie uit profielen, uit reviews of van opdrachtgevers aan derden.</li>
      <li>Wij benaderen geen opdrachtgevers of professionals voor acquisitie voor derden.</li>
      <li>Interimreview.nl is gratis voor opdrachtgevers en professionals.</li>
      <li>Wij streven naar eerlijke reviews. We verwijderen alleen reviews als deze zonder respect voor de professional
        zijn geschreven, of indien de professional ons heeft overtuigd van de onjuistheid van de review.</li>
    </ul>

    <p>Heb je vragen of opmerkingen over Interimreview.nl? Neem
    gerust <a href='mailto:interimreview@gmail.com'>contact</a> met ons op.</p>
  </div>
);

export default Terms;
