import * as React from 'react'; // Always necessary in tsx (desugaring assumes React module object)
import { Location } from 'history'; // This is the Location that has a key
                                    // (don't confuse with DOM Location in default lib)
import { Component, Props, SFC } from 'react';
import { RouteComponentProps, withRouter } from 'react-router-dom';

import { buildInfo } from 'util/BuildInfo';

const scrollKeyPrefix = 'scrollPos_';

// Scroll-restoration component that uses sessionStorage to keep track of scroll positions. Safe to run on server.

// Doesn't use mouse events to capture scroll position, since we only need to store it when navigating between pages
// of the app itself. For external links, the browser takes care to restore the position on back navigation, and when
// closing the page, there is no need to keep track of the position, as the page with that key will never be opened
// again.

// NOTE: The scroll position is restored on componentDidUpdate, which means the page heigh must be the final page height
//       at that time, or we won't be able to scroll to the end.
//       All images need to have a height, and images that cause wrapping need a width as well.

// TODO: After testing, remove messy commented debug code

interface RestoreScrollOwnProps {
  // currently empty
}
interface RestoreScrollProps extends RestoreScrollOwnProps, RouteComponentProps<any> {}
// type param <any> in RouteComponentProps is for match<P> (coming from Router<P>), and denotes type of `params`.

// Typing is tricky, we need the named own props type to type withRouter<RestoreScrollOwnProps> correctly. Without
// a parameter it becomse withRouter<RestoreScrollProps> and assumes the extra router props are exposed on the exported
// type, giving a cryptic error in App.tsx:
//   Type '{ children: Element; }' is not assignable to type 'Readonly<RouteComponentProps<any>>'

// If we use a type:
// type RestoreScrollProps = RestoreScrollOwnProps & RouteComponentProps<any>;
// then ts recognizes the intersection type, and infers <RestoreScrollOwnProps> for withRouter without expl. param, but
// only if it is an interface. Using type RestoreScrollOwnProps = {},
// or type RestoreScrollProps = RouteComponentProps<any>, or
// type RestoreScrollProps = {} & RouteComponentProps<any>, will infer withRouter<any>, exposing the route types on
// the exported type.

// Chrome 61 doesn't support document.body.scrollTop anymore, so we need to use document.documentElement
// See https://dev.opera.com/articles/fixing-the-scrolltop-bug/
const getScrollingElt = () : Element | null => {
  // Need (global as any) to access client-specific properties like document safely, as document would
  // throw identifier not defined error.
  if ((global as any).document) { // We're rendering on the client
    if ('scrollingElement' in document) {
      return document.scrollingElement!; // won't be null if it exists
    } else {
      // Fallback for legacy browsers
      if (navigator.userAgent.indexOf('WebKit') !== -1) {
        return (document as any).body; // Cast to any, since TypeScript 2.7 deduces document : never because of
                                       // condition ('scrollingElement' in document)
      } else {
        return (document as any).documentElement;
      }
    }
  } else {
    return null; // We're rendering on the server
  }
};

class RestoreScroll extends Component<RestoreScrollProps, {}> {
  // instance props for client globals to prevent problems on server
  // cannot use type WindowSessionStorage as that is the type of an object containing sessionStorage.
  sessionStorage : Storage = (global as any).sessionStorage; // safe global access that won't crash on server
  scrollingElt : Element | null = getScrollingElt();

  pageScrollKey(location : Location) {
    const pageKey = location.key ? location.key : '_start';
    return scrollKeyPrefix + pageKey;
  }

  componentWillUpdate(nextProps : RestoreScrollProps) {
    // On a location change, save the current scroll position in sessionStorage.
    if (this.sessionStorage && this.scrollingElt) {
      const currentLocation = this.props.location;
      const nextLocation = nextProps.location;

      if (currentLocation !== nextLocation) {
        fetch('/api/navigate?path=' + encodeURIComponent(nextLocation.pathname));

        // console.log('willUpdate: ' + this.scrollingElt.scrollTop +
        //   this.pageScrollKey(this.props.location) + ' ~> ' + this.pageScrollKey(prevLocation));
        // console.log('setting ' + this.pageScrollKey(currentLocation) +
        //   ' from ' + this.sessionStorage.getItem(this.pageScrollKey(currentLocation)) +
        //   ' to ' + this.scrollingElt.scrollTop);
        this.sessionStorage.setItem(this.pageScrollKey(currentLocation), '' + this.scrollingElt.scrollTop);
      }
    }
  }

  // On a location change, check if there is a saved scroll position in sessionStorage, and use it
  componentDidUpdate(prevProps : RestoreScrollProps) {
    if (this.sessionStorage && this.scrollingElt) {
      const currentLocation = this.props.location;
      const prevLocation = prevProps.location;

      if (currentLocation !== prevLocation) {
        // console.log(this.scrollingElt.scrollTop,
        //   this.pageScrollKey(prevProps.location) + ' ~> ' + this.pageScrollKey(currentLocation));

        const savedScrollPos = this.sessionStorage.getItem(this.pageScrollKey(currentLocation));
        const newScrollPos = savedScrollPos ? +savedScrollPos : 0;
        this.scrollingElt.scrollTop = newScrollPos;
        if (!savedScrollPos) {
          // console.log('new page', this.pageScrollKey(currentLocation));
          this.scrollingElt.scrollTop = 0;
        } else {
          // console.log('previously visited page, setting scroll: ' + savedScrollPos +
          //   ' (old value was: ' + this.scrollingElt.scrollTop + ', body height: ' +
          //   this.scrollingElt.scrollHeight + ')');
          this.scrollingElt.scrollTop = +savedScrollPos;
        }
      }
    }
  }

  render() {
    return (
      <div>
        {/* buildInfo.isDevServer && <DisplayScrollKeys sessionStorage={this.sessionStorage}/>*/}
        {this.props.children}
      </div>
    );
  }
}

// Hacky debug component, breaks react pre-rendering because except on a clean load, sessionStorage keys will populate
// the list on the client, and not on the server. Therefore, we only show it on dev-server.
const DisplayScrollKeys : SFC<{sessionStorage : Storage}> = ({sessionStorage: sessionStorage = ({} as any)}) => (
  <div style={{position: 'fixed', zIndex: 2000, backgroundColor: 'lightgrey'}}>
    { Object.keys(sessionStorage)
        .filter((k) => k.startsWith(scrollKeyPrefix))
        .map((k, i) =>
          <div key={'' + i}>
            {k + '     ' + sessionStorage.getItem(k)}
          </div>
        ) }
  </div>
);
const exp = withRouter(RestoreScroll); // withRouter passes `location` prop to RestoreScroll
export default exp;
