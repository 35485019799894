import * as React from 'react';
import { Component, Fragment, Props } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import * as qs from 'query-string';

import * as Actions from 'client/redux/Actions';
import * as Api from 'client/Api';
import * as Types from 'client/Types';
import * as Utils from 'util/Utils';

/*
TODO:
- Hide during auth initialization?
 */
interface UserProps extends RouteComponentProps<{}> { // declares match, location, etc. props ({} is url params type)
  authUser : Types.Professional | null,
  queriedString : string,
  queriedOffset : number,
  queriedLimit : number,
  dispatch : Actions.CombinedDispatch
}
interface UserState {}
class User extends Component<UserProps, UserState> {
  state : UserState = { // Adding this sig gives more informative errors
  };

  updateReviewStats = () => {
    Api.updateReviewStats();
  }

  generateFakeProfessional = async () => {
    const newProf = await Api.createFakeProfessional();
    this.props.dispatch(Actions.fetchProfessionals(
      this.props.queriedString, this.props.queriedOffset, this.props.queriedLimit
    )); // re-fetch previous query
    alert(`Professional ${newProf.userId} gegenereerd: ${newProf.firstName} ${newProf.lastName}`);
  }

  render() {
    const {authUser, dispatch} = this.props;
    const isAdmin = authUser ? authUser.accessLevel === 'admin' : false;
    return (
      <div className='user-menu'>
        { authUser === null
          ? <Fragment>
              <Link to='/registration/register'>Maak een profiel aan</Link> of {' '}
              <a href='javascript:void(0)' // href is required for correct hover style
                onClick={() => dispatch(Actions.showLoginModal())}>Log in</a>{' '}
              op Interimreview.nl.
            </Fragment>
          : <Fragment>
              <div className='professional-inline'>
                <div className='professional-inline-details'>
                  <Link to={'/professional/' + authUser.userId}>
                    <div className='name'><img className='avatar-thumbnail-small' src={authUser.avatar_120x120}/>
                      {authUser.firstName} {authUser.lastName}
                    </div>
                  </Link>
                  <div className='job-title'>{authUser.professionalType}</div>
                  <div className='location'>{authUser.location}</div>
                  <div>Reviews: {authUser.nrOfReviews}</div>
                </div>
              </div>
              <div className='avg-grade'>
                <div className='grade'>
                  <span className='glyphicon glyphicon-star-empty'></span>{Utils.showGrade(authUser.averageGrade)}
                </div>
              </div>
              { isAdmin &&
                <Fragment>
                  <div className='label label-default'>Admin</div>
                  <div>
                    <input type='button' className='btn btn-xs btn-default' value='Cijfers herberekenen'
                          onClick={this.updateReviewStats}/>
                    <input type='button' className='btn btn-xs btn-default' value='Genereer professional'
                          onClick={this.generateFakeProfessional}/>
                  </div>
                </Fragment> }
            </Fragment> }
      </div>
    );
  }
}


const mapStateToProps = ({auth: {authUser}, professionals: {queriedString, queriedOffset, queriedLimit}} : Types.ReducerState) => ({
  authUser, queriedString, queriedOffset, queriedLimit
});

export default withRouter(connect(mapStateToProps)(User));
