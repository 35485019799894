import * as React from 'react';
import { Component, Fragment, Props, SFC } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as qs from 'query-string';

import * as Actions from 'client/redux/Actions';
import * as Types from 'client/Types';
import * as Utils from 'util/Utils';

/*
TODO:
- Probably better to show 'mail sent' in Registration and only do validation here. The current convoluted architecture
  was more logical in angular.
*/

interface ValidateProps extends RouteComponentProps<{}> { // declares match, location, etc. props ({} is url params type)
  dispatch : Actions.CombinedDispatch
}
interface ValidateState {
  email : string | null,
  userId : string | null,
  validationCode : string | null,
  validationSuccessful : boolean,
  validationRequestCompleted : boolean,
  validatedUser : Types.Professional | null
}
class Validate extends Component<ValidateProps, ValidateState> {
  state : ValidateState = { // Adding this sig gives more informative errors
    email: null,
    userId: null,
    validationCode: null,
    validationSuccessful: false,
    validationRequestCompleted: false,
    validatedUser: null,
  };

  validateUser = (userId : string | undefined, validationCode : string | undefined) => {
    if (userId && validationCode) {
      this.props.dispatch(Actions.validateUserId(userId, validationCode))
      .then((validatedUser) => {
        this.setState({
          validationRequestCompleted: true,
          validationSuccessful: true,
          validatedUser
        });
      })
      .catch((err) => {
        this.setState({ // TODO: distinguish between server error and incorrect/expired code (need more info in error obj.)
          validationRequestCompleted: true,
          validationSuccessful: false
        });
      });
    }
  }

  // TODO: migrate to getDerivedStateFromProps in react@16.3
  setStateFromQueryParamString(queryParamStr : string) {
    const {email, uid: userId, code: validationCode} = qs.parse(queryParamStr);
    // url parameters are ../validation?email=.. for showing email has been sent (redirect from Register.tsx)
    //                    ../validation?uid=..&code=.. for actually validating account (link from e-mail)
    if (email && typeof email === 'string' && userId && typeof userId === 'string' && validationCode && typeof validationCode === 'string') {
      this.setState({email, userId, validationCode});
      this.validateUser(userId, validationCode);
    } else {
      this.setState({ // TODO: distinguish between server error and incorrect/expired code (need more info in error obj.)
        validationRequestCompleted: true,
        validationSuccessful: false
      });
    }
  }

  componentDidMount() {
    this.setStateFromQueryParamString(this.props.location.search);
  }

  componentWillReceiveProps(nextProps : ValidateProps) {
    if (this.props.location.search !== nextProps.location.search) {
      this.setStateFromQueryParamString(nextProps.location.search);
    }
  }

  handleLoginNewUser = () => {
    this.props.dispatch(Actions.showLoginModal(this.state.validatedUser ? this.state.validatedUser.username : ''));
  }

  render() {
    const validatedUser =
      this.state.validatedUser ? this.state.validatedUser : {firstName: '', lastName: '', username: ''};
    const email = this.state.email;

    return (
      <div className='validation'>
        { this.state.validationRequestCompleted
        ? ( this.state.validationRequestCompleted
          ? <div>
              Account voor {validatedUser.firstName} {validatedUser.lastName} met gebruikersnaam {validatedUser.username} is gevalideerd.
              <div className='form-group'>
                <input type='button' className='btn btn-sm btn-default' value='Login'
                       onClick={this.handleLoginNewUser}/>
              </div>
            </div>
          : <div>
              Validatiecode is ongeldig of verlopen.
            </div> )
        : ( this.state.validationCode
            ? <div>
                Account wordt gevalideerd.
              </div>
            : <div>
                Een e-mail met instructies om het registratieproces te voltooien is verstuurd naar<br/>{email}.
              </div> ) }
      </div>
    );
  }
}


const mapStateToProps = ({} : Types.ReducerState) => ({
});

export default connect(mapStateToProps)(Validate);
