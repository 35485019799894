import { Component } from 'react';

// As long as changes is object literal, S will be matched to first arg, and error on failing
// picked type (instead of choosing a more general S, such as {})
// Partial might seem usable too, but allows {prop: undefined}, which is not what we want here.
/* tslint:disable prefer-object-spread */
// cannot use spread because of https://github.com/Microsoft/TypeScript/issues/14409
// (using S extends object doesn't help)
export const applyChanges = <S extends object, K extends keyof S>(state : S, changes : Pick<S, K>) : S =>
  Object.assign({}, state, changes);

// Safer replacement for setState((s : State) => ..), could be added as a mixin.
// NOTE: Not entirely safe: If all state props are present in the returned value, additional props are not errors.
//       As a consequence, also won't signal setting props if State == {}
export const updateState =
  <S, P, K extends keyof S>(comp : Component<P, S>, f : (s : S) => Pick<S, K>) : void => {
  comp.setState(f);
};

/* tslint:enable prefer-object-spread */
