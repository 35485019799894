import * as React from 'react';
import { Link } from 'react-router-dom';

const Contact : React.SFC<{}> = () => (
  <div className='info'>
    <h2>Contact</h2>
    <p>Heb je vragen, opmerkingen of ideeën over Interimreview.nl?  Twijfel je of het bij jou past? Neem contact met ons
    op per <a href='mailto:interimreview@gmail.com'>mail</a>.</p>

    <p>Praktische vragen kun je voorlopig ook het beste per mail stellen, denk bijvoorbeeld aan:</p>

    <ul>
      <li>Het opvragen van een nieuw wachtwoord.</li>
      <li>Ondersteuning bij het aanmaken van een profiel.</li>
      <li>Het verwijderen van een review.</li>
    </ul>

    <p>Heb je liever telefonisch contact? Laat je contactgegevens achter in een mailtje en je wordt binnen twee
    werkdagen teruggebeld.</p>
  </div>
);

export default Contact;
