import * as React from 'react';
import { Link } from 'react-router-dom';

const ProfessionalInfo : React.SFC<{}> = () => (
  <div className='info'>
    <h2>Informatie voor professionals</h2>
    <p>Interimreview.nl is een jong platform met volwassen ambities. Wij willen de interim markt transparant maken. Om
    dit waar te maken hebben wij jou als professional nodig. We vragen jou om een profiel aan te maken.</p>

    <h4>Waarom zou je nu een profiel aanmaken?</h4>

    <ul>
      <li>Omdat jij graag direct gevonden wordt door opdrachtgevers.</li>
      <li>Omdat jij je graag onderscheidt op kwaliteit.</li>
      <li>Omdat dit een handig instrument is om waardevolle feedback te vragen aan je opdrachtgevers.</li>
      <li>Omdat ik onder de eerste 1000 aanmelders een iPad verloot.</li>
      <li>Omdat je mij (Lin) er een groot plezier mee doet.</li>
      <li>Voor iedere aanmelding in deze opstartfase garanderen wij dat jouw lidmaatschap levenslang gratis blijft.</li>
      <li>Heb je vragen, <a href='mailto:interimreview@gmail.com'>mail</a> ons vooral.</li>
    </ul>
  </div>
);

export default ProfessionalInfo;
