import * as React from 'react';
import { Component, Fragment, Props, SFC } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import * as qs from 'query-string';

import * as Actions from 'client/redux/Actions';
import * as Types from 'client/Types';
import * as Utils from 'util/Utils';
import * as Api from 'client/Api';

/*
TODO:
*/

interface ValidateReviewProps extends RouteComponentProps<{}> { // declares match, location, etc. props ({} is url params type)
  dispatch : Actions.CombinedDispatch
}
interface ValidateReviewState {
  reviewee : Types.Professional | null,
  validationError : string | null
}
class ValidateReview extends Component<ValidateReviewProps, ValidateReviewState> {
  state : ValidateReviewState = { // Adding this sig gives more informative errors
    reviewee: null,
    validationError: null
  };

  validateReview = (reviewId : string | string[] | null | undefined, validationCode : string | string[] | null | undefined) => {
    if (reviewId && typeof reviewId === 'string' && validationCode && typeof validationCode === 'string') {
      this.props.dispatch(Actions.validateReviewId(reviewId, validationCode))
      .then((validatedReview) => {
        Api.getProfessional(validatedReview.revieweeId)
        .then((reviewee) => {
          this.setState({reviewee});
        });
      })
      .catch((err) => {
        this.setState({ // TODO: distinguish between server error and incorrect/expired code (need more info in error obj.)
          validationError: '' + err
        });
      });
    } else {
      this.setState({
        validationError: 'Url heeft incorrect formaat.'
      });
    }
  }

  setStateFromQueryParamString(queryParamStr : string) {
    const {rid: reviewId, code: validationCode} = qs.parse(queryParamStr);
    // url parameters are ../validation?rid=..&code=.. for validating review (link from e-mail)
    this.validateReview(reviewId, validationCode);
  }

  // TODO: migrate to getDerivedStateFromProps in react@16.3
  componentDidMount() {
    this.setStateFromQueryParamString(this.props.location.search);
  }
  componentWillReceiveProps(nextProps : ValidateReviewProps) {
    if (this.props.location.search !== nextProps.location.search) {
      this.setStateFromQueryParamString(nextProps.location.search);
    }
  }

  render() {
    return (
      <div className='validation'>
        { this.state.reviewee !== null && this.state.validationError !== null
        ? // NOTE: bit simpler than registration/Validate, since we don't use this page to show the email-sent message
          //       (this is done with a popup instead)
          <div>
            E-mailadres wordt gevalideerd.
          </div>
        : <div>
            { this.state.reviewee !== null &&
              <div>
                Bedankt voor je review. Zodra {this.state.reviewee.firstName} de review accepteert wordt deze geplaatst.
                <div className='continue-button form-group'>
                  <input type='button' className='btn btn-sm btn-default' value='Ok' onClick={() => this.props.history.go(-1)}/>
                </div>
              </div> }
            { this.state.validationError !== null &&
              // Until we can distinguish between server errors and incorrect-code errors, just show a generic error
              <div>
                Ongeldige of verlopen validatiecode.
              </div> }
          </div> }
      </div>
    );
  }
}


const mapStateToProps = ({} : Types.ReducerState) => ({
});

export default connect(mapStateToProps)(ValidateReview);
