import * as React from 'react';
import { SFC } from 'react';

interface LabeledVal {
  val : string,
  label : string
}

// Select component that shows black selection when value ===  '', but no blanks after value is set.
// (similar to angular select elements)
interface SelectProps {
  options : (string[] | LabeledVal[]), // array elements can or strings or records
  value : string,
  required? : boolean,
  onChange : (evt : React.ChangeEvent<HTMLSelectElement>) => void
}
export const Select : SFC<SelectProps> = (props) => {
  const valsAndLabels = isStringArray(props.options)
    ? props.options.map((opt) => ({val: opt, label: opt}))
    : props.options;
  return (
    <select value={props.value} onChange={props.onChange} required={props.required}>
      { (props.value === '') &&
        <option value=''></option> }
      { valsAndLabels.map(({val, label}) =>
            <option value={val} key={val}>{label}</option>) }
    </select>
  );
};

function isStringArray(arr : string[] | LabeledVal[]) : arr is string[] {
  return arr.length === 0 || typeof arr[0] === 'string';
}
