import * as React from 'react';
import { Link } from 'react-router-dom';

const ReviewerInfo : React.SFC<{}> = () => (
  <div className='info'>
    <h2>Informatie voor de reviewer</h2>
    <p>Interimreview.nl is een jong platform met volwassen ambities. Wij willen de interim markt transparant maken. Om
    dit waar te maken hebben wij jou als reviewer nodig. We vragen jou om een review te schrijven over het werk dat
    een interim professional voor of met jou heeft gedaan.</p>

    <h4>Waarom zou je nu een review schrijven?</h4>

    <ul>
      <li>Omdat jij graag reviews leest voordat je een interim professional inhuurt.</li>
      <li>Omdat jij gelooft dat reviewen kwaliteit stimuleert.</li>
      <li>Omdat jij de professional er een groot plezier mee doet.</li>
      <li>Omdat het nog geen vijf minuten van je tijd kost.</li>
    </ul>
  </div>
);

export default ReviewerInfo;
