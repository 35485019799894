import * as React from 'react';
import { Component, Fragment, Props, SFC } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import _uniq from 'lodash-es/uniq';

import { buildInfo } from 'util/BuildInfo';
import * as shared from 'shared/shared';
import * as Actions from 'client/redux/Actions';
import * as Types from 'client/Types';
import * as Utils from 'util/Utils';
import { NarrowDescriptionEdit, PROF_TYPE_OTHER_VAL } from 'components/professional/NarrowDescription';
import { WideDescriptionEdit } from 'components/professional/WideDescription';

/*
TODO:
*/

interface RegisterProps extends RouteComponentProps<{}> { // declares match, location, etc. props ({} is url params type)
  dispatch : Actions.CombinedDispatch
}
interface RegisterState {
  editedProf : Types.EditedProfessional,
  password : string,
  professionalTypeOther : string // for the text field in case professional type 'Anders:' is selected
}
class Register extends Component<RegisterProps, RegisterState> {
  state : RegisterState = { // Adding this sig gives more informative errors
    editedProf: {
      username: '',
      email: '',

      firstName: '',
      lastName: '',
      location: '',
      linkedin: '',
      website : '',
      professionalType: '',
      keywords: '',
      experienceLevel: '',
      experienceSummary : '',
      rate: '',
      availability: '',
      employment: ''
    },
    password: '',
    professionalTypeOther: ''
  };

  handleRegister = () => {
    // console.log('handleRegister', this.state.editedProf, this.validateProfForm());
    if (this.state.editedProf && this.validateProfForm()) { // Will always be the case
      // If 'Anders:' is selected, take the value from professionalTypeOther
      const professionalType = this.state.editedProf.professionalType !== PROF_TYPE_OTHER_VAL
        ? this.state.editedProf.professionalType
        : this.state.professionalTypeOther;

      const rate = this.state.editedProf.rate !== undefined ? parseInt(this.state.editedProf.rate) : undefined;
      const keywords = _uniq(
        this.state.editedProf.keywords.split(',').map((keyword) => keyword.trim()).filter((keyword) => keyword !== '')
      );

      // add https if necessary
      const website = this.state.editedProf.website === undefined ? undefined :
        (this.state.editedProf.website.match(/^https?:\/\//) ? '' : 'https://') + this.state.editedProf.website;
      const linkedin = this.state.editedProf.linkedin === undefined ? undefined :
        (this.state.editedProf.linkedin.match(/^https?:\/\//) ? '' : 'https://') + this.state.editedProf.linkedin;

      const newProf : Types.BaseProfessional = {...this.state.editedProf,
        professionalType, rate, keywords, website, linkedin
      };

      this.props.dispatch(Actions.registerProfessional(newProf, this.state.password))
      .then(() => {
        this.props.history.push(`/registration/validate?email=${encodeURIComponent(newProf.email)}`);
      })
      .catch((err) => {
        alert(err);
      });
    }
  }

  handleCancelRegistration = () => { // TODO: check for unsaved changes (also not implemented in Angular version)
    // console.log('canceling');
    this.props.history.go(-1);
  }

  handleAutoFill = () => {
    this.setState({
      editedProf: {
        username: 'tinyrick',
        firstName: 'Rick',
        lastName: 'Sanchez',
        employment: 'Zelfstandig',
        experienceLevel: 'Senior',
        availability: 'Ja',
        professionalType: PROF_TYPE_OTHER_VAL,
        location: 'Utrecht',
        rate: '500',
        email: 'martijn+tinyrick@oblomov.com',
        keywords:  'testing, one, two',
        linkedin: 'http://nl.linkedin.com/pub/martijn-schrage/6/677/505',
        website : 'http://www.oblomov.com',
        experienceSummary : 'Het testen van Interimreview.nl.'
      },
      professionalTypeOther: 'Professional tester'
    });
  }

  handleChangePassword = (evt : React.ChangeEvent<HTMLInputElement>) => {
    this.setState({password: evt.currentTarget.value});
  }

  setProfessionalTypeOtherHandler = (evt : React.ChangeEvent<HTMLInputElement>) => {
    this.setState({professionalTypeOther: evt.currentTarget.value});
  }

  updateEditedProfHandler : (key : string) => (evt : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => void = (key) => (evt) => {
    const newValue = evt.currentTarget.value;
    this.setState(({editedProf}) => ({
      editedProf: {...editedProf, [key]: newValue}
    }));
  }

  validateProfForm = () => {
    // console.log('validating', this.state.editedProf);
    const formElt = document.forms['registrationForm' as any];
    const htmlValidity = formElt !== undefined && formElt.checkValidity();
    // TODO: this doesn't work when setState modifies validity (extra setState call to update checkValidity)

    return this.state.editedProf !== null && htmlValidity &&
      (!this.state.editedProf.rate || /^\d+$/.test(this.state.editedProf.rate));
  }

  render() {
    return (
      <div className='registration'>
        <h2>Nieuw profiel</h2>

        <form name='registrationForm'>
          <table className='property-list'><tbody>
            <tr>
              <td className='prop-label'>Gebruikersnaam</td>
              <td className='prop-value username'>
                <input type='text' required className='input-sm form-control'
                         autoCorrect='off' autoCapitalize='off'
                         autoComplete='username'
                       value={this.state.editedProf.username} onChange={this.updateEditedProfHandler('username')}/>
              </td>
            </tr>
            <tr>
              <td className='prop-label'>Wachtwoord</td>
              <td className='prop-value password'>
                <input type='password' required className='input-sm form-control'
                        autoCorrect='off' autoCapitalize='off'
                        autoComplete='new-password'
                       value={this.state.password} onChange={this.handleChangePassword}/>
              </td>
            </tr>
          </tbody></table>

          <div className='professional-description'>
            <div className='narrow-description' data-is-editing={true}>
              <NarrowDescriptionEdit editedProf={this.state.editedProf}
                                    updateEditedProfHandler={this.updateEditedProfHandler}
                                    professionalTypeOther={this.state.professionalTypeOther}
                                    setProfessionalTypeOtherHandler={this.setProfessionalTypeOtherHandler}/>
            </div>
            <div className='force-wrap visible-xs'></div> {/* Necessary for Safari */}
            <div className='wide-description' data-is-editing={true}>
              <WideDescriptionEdit editedProf={this.state.editedProf}
                                  updateEditedProfHandler={this.updateEditedProfHandler}/>
            </div>
          </div>
        </form>
        <div className='buttons'>
          <input type='button' className='btn btn-sm btn-default' value='Profiel aanmaken' onClick={this.handleRegister}
                 disabled={!this.validateProfForm()}/>
          { !buildInfo.isProduction &&
            <input type='button' className='btn btn-sm btn-default' value='Auto-fill' onClick={this.handleAutoFill}/> }
          <input type='button' className='btn btn-sm btn-default' value='Annuleren' onClick={this.handleCancelRegistration}/>
        </div>
      </div>
    );
  }
}


const mapStateToProps = ({} : Types.ReducerState) => ({
});

export default connect(mapStateToProps)(Register);
