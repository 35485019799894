import * as React from 'react';
import { Link } from 'react-router-dom';

const About : React.SFC<{}> = () => (
  <div className='info'>
    <h2>Wat is Interimreview.nl</h2>

    <p>Interimreview.nl is een platform waar goede interim profesionals direct gevonden worden door opdrachtgevers. Ik
    wil hiermee bijdragen aan een eerlijke en transparante markt van interim professionals en opdrachtgevers. Mijn naam
    is <Link to='/professional/p-2'>Lin Le Roux</Link>, ik heb Interimreview.nl opgericht. Ik heb tien jaar ervaring in
    de interim markt als consultant en interim manager. Deze markt kan beter. Interimreview gaat daaraan bijdragen. Het
    geven en verzamelen van reviews staat hierin centraal. Zo onstaat hier een eerlijk beeld van de professional en
    worden zij direct vindbaar voor opdrachtgevers. Kwaliteit wordt onderscheiden en tarieven worden beter
    vergelijkbaar. Dit zou een plek moeten worden waar je vooral goede en eerlijke interim professionals kan vinden.</p>

    <p>Wij zijn in de opstartfase. Je leest dit waarschijnlijk omdat je gevraagd bent in deze eerste fase mee te bouwen.
    Dat doe je door een profiel aan te maken of een review te schrijven. Welkom bij Interimreview.nl en bij voorbaat
    hartelijk bedankt!</p>
  </div>
);

export default About;

