import * as React from 'react';
import { Component, Props } from 'react';
import { connect } from 'react-redux';
import { MenuItem, Nav, Navbar, NavDropdown, NavItem } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { Route, RouteComponentProps, withRouter } from 'react-router-dom';
import * as qs from 'query-string';
import * as History from 'history';

import * as Types from 'client/Types';
import * as Actions from 'client/redux/Actions';
import { buildInfo } from 'util/BuildInfo';

/*
TODO:
- BUG: when query is changed on navigation, search field is not updated. Probably because of connect
- reload on profiel aanmaken gives wrong menu item background color
- Current issues with react-bootstrap:
  - Select in dropdown doesn't collapse entire menu
  - Can we trigger collapse with search?
*/
interface MenuBarProps extends RouteComponentProps<{}> { // declares match, location, etc. props ({} is url params type)
  authUser : Types.Professional | null,
  dispatch : Actions.CombinedDispatch
}
interface MenuBarState {
  queryStr : string
}
class MenuBar extends Component<MenuBarProps, MenuBarState> {
  state : MenuBarState = { // Adding this sig gives more informative errors
    queryStr: ''
  };

  setStateFromQueryParamString(queryParamStr : string) {
    const {q} = qs.parse(queryParamStr);
    this.setState({queryStr: q ? '' + q : ''}); // use '' if url has no parameter "?q=.."
  }

  componentDidMount() {
    this.setStateFromQueryParamString(this.props.location.search);
    // We only sync the state with the url on mount, so search field doesn't get reset when navigating to
    // professionals?q=
    // The search field is initialized with the url but doesn't reflect it's state, just like in original app.
  }

  handleChangeQueryStr = (evt : React.ChangeEvent<HTMLInputElement>) => {
    this.setState({queryStr: evt.currentTarget.value});
  }

  handleSearch = (evt : React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    this.props.history.push(`/professionals?q=${this.state.queryStr}`);
  }

  // Passing prof here makes an extra null check unnecessary (efficiency loss is negligible)
  deleteProfile = (prof : Types.Professional) => (evt : React.SyntheticEvent<any>) => {
    evt.preventDefault();
    this.props.dispatch(Actions.showDialog(
      'Profiel verwijderen?',
      `Weet je zeker dat je je Interimreview.nl profiel wilt verwijderen? Dit kan niet ongedaan gemaakt worden.`,
      'Verwijderen', 'Annuleren', () => {
        this.props.dispatch(Actions.deleteProfessional(prof.userId));
        this.props.dispatch(Actions.syncAuth());
        this.props.history.replace('/'); // Just replace current page with home, in case user was on their profile page.
      }, () => {}
    ));
  }

  render() {
    const isAuth = this.props.authUser !== null;
    return (
      <Navbar inverse fixedTop collapseOnSelect>

          <div className='row'>
            <div className='col-md-1'></div>
            <div className='col-md-10'>
              {/* Navbar code from https://getbootstrap.com/components/#navbar */}
              {/* Stretch at the top partially taken from https://www.youtube.com/watch?v=no-Ntkc836w + navbar-static-top */}
              <nav>
                <div className='container-fluid'>
                  {/* Brand and toggle get grouped for better mobile display */}

                  <Navbar.Header>
                    <Navbar.Toggle/>

                    <LinkContainer exact={true} to='/' className='navbar-brand'>
                      <span>
                        <img className='brand-logo' src='/img/logo-50x50.png'/>
                        <span className='brand-name'>Interimreview.nl</span>
                        { !buildInfo.isProduction &&
                          <div>
                            <div className='screen-size-label visible-xs'>xs</div>
                            <div className='screen-size-label visible-sm'>sm</div>
                            <div className='screen-size-label visible-md'>md</div>
                            <div className='screen-size-label visible-lg'>lg</div>
                          </div> }
                      </span>
                    </LinkContainer>
                  </Navbar.Header>

                    {/* Collect the nav links, forms, and other content for toggling */}
                  {/* <div className='collapse navbar-collapse' id='main-menu-bar'> */}
                  <Navbar.Collapse>
                    <Nav>
                      <LinkContainer exact={false} to='/professionals?q='><NavItem>Professionals</NavItem></LinkContainer>
                    </Nav>

                    <form id='menu-search-form' className='navbar-form navbar-right' role='search' onSubmit={this.handleSearch}>
                      <div className='form-group'>
                        <input type='text' className='input-sm form-control'
                               value={this.state.queryStr} onChange={this.handleChangeQueryStr}
                               placeholder='Zoek professional'/>
                      </div>
                      <button type='submit' className='btn btn-sm btn-default form-control-sm'>
                        <span className='glyphicon glyphicon-search'></span>
                      </button>
                    </form>

                    <Nav pullRight >
                      <UserMenuItems authUser={this.props.authUser}
                                     deleteProfile={this.deleteProfile}
                                     dispatch={this.props.dispatch}/>
                    </Nav>
                    { !isAuth &&
                        <Nav pullRight>
                          <LinkContainer exact={true} to='/registration/register'><NavItem>Profiel aanmaken</NavItem></LinkContainer>
                        </Nav> }
                  </Navbar.Collapse>
                </div> {/* /.container-fluid */}
              </nav>
            </div>
            <div className='col-md-1'></div>
          </div>

      </Navbar>
    );
  }
}

const UserMenuItems : React.SFC<{
        authUser : Types.Professional | null,
        deleteProfile : (prof : Types.Professional) => (evt : React.SyntheticEvent<any>) => void,
        dispatch : Actions.CombinedDispatch
      }> = ({authUser, deleteProfile, dispatch}) => {
    if (authUser === null) {
      return (
        <li>
          <a onClick={() => dispatch(Actions.showLoginModal())} role='button'>Inloggen</a>
        </li>
      );
    } else {
      return (
        <NavDropdown title={authUser.firstName} id='user-dropdown-menu'>
          <MenuItem onClick={() => dispatch(Actions.logout())}>Uitloggen</MenuItem>
          <LinkContainer to={'/professional/' + authUser.userId}><MenuItem>Profiel bekijken</MenuItem></LinkContainer>
          <MenuItem onClick={deleteProfile(authUser)}>Profiel verwijderen</MenuItem>
        </NavDropdown>
      );
    }
  };

const mapStateToProps = ({auth: {authUser}} : Types.ReducerState) => ({
  authUser
});

export default withRouter(connect(mapStateToProps)(MenuBar));
