import * as React from 'react'; // Always necessary in tsx (desugaring assumes React module object)
import { Component, SFC } from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router';
import { BrowserRouter, Link, Redirect, Route, StaticRouter, Switch } from 'react-router-dom';
import * as qs from 'query-string';

import * as Actions from 'client/redux/Actions';
import * as Types from 'client/Types';
import RestoreScroll from 'components/RestoreScroll';
import MenuBar from 'components/MenuBar';
import User from 'components/User';
import Home from 'components/Home';
import About from 'components/info/About';
import Terms from 'components/info/Terms';
import Contact from 'components/info/Contact';
import ProfessionalInfo from 'components/info/ProfessionalInfo';
import ReviewerInfo from 'components/info/ReviewerInfo';
import LoginModal from 'components/LoginModal';
import ConfirmModal from 'components/ConfirmModal';
import Professionals from 'components/professionals/Professionals';
import ProfessionalContainer from 'components/professional/Professional'; // TODO: kind of annoying this name is off
import ValidateReview from 'components/reviews/ValidateReview';
import Register from 'components/registration/Register';
import Validate from 'components/registration/Validate';
import ResetRequest from 'components/registration/ResetRequest';
import ResetPassword from 'components/registration/ResetPassword';
import Footer from 'components/Footer';

const Router : React.SFC<{}> = () => (
  <BrowserRouter>
    <RestoreScroll>
      <CatchLoginUrl>
        <div className='column-stretch'>
          <LoginModal/>
          <ConfirmModal/>
          <MenuBar/>
          <div className='body-content'>
            <Routes/>
          </div>
          <Footer/>
        </div>
      </CatchLoginUrl>
    </RestoreScroll>
  </BrowserRouter>
);

// Component that watches url for ?login=username, and opens login modal for username
interface CatchLoginUrlCompProps extends RouteComponentProps<any> {
  dispatch : Actions.CombinedDispatch
}
class CatchLoginUrlComp extends Component<CatchLoginUrlCompProps, {}> {
  openModalOnLoginQuery() {
    const {login: username} = qs.parse(this.props.location.search);
    if (typeof username === 'string') {
      // replace history with pathname, dropping the query string (we don't handle ?login with other query components)
      this.props.history.replace(this.props.location.pathname, this.props.location.state);
      // open login modal
      this.props.dispatch(Actions.showLoginModal(username));
    }
  }

  componentDidMount() {
    this.openModalOnLoginQuery();
  }

  componentDidUpdate(prevProps : CatchLoginUrlCompProps) {
    // Would have saved a render cycle if we did this in deprecated componentWillReceiveProps.
    // TODO: can actually move this to getDerivedStateFromProps, as no instance-specific code is used.
    this.openModalOnLoginQuery();
  }
  render() {
    return this.props.children;
  }
}
const CatchLoginUrl = withRouter(connect()(CatchLoginUrlComp));

/* Explicit use of <Wrap>..</Wrap> makes it clear which components take RouteComponentProps.
   For SFC, typings work perfectly (signal both missing and redundant props), but for class component, providing
   redundant props does not cause an error (e.g. in <Wrap><ResetRequest {...props}/></Wrap>}/).
   TODO: Maybe this can be improved without adding too much complexity?
*/
const Routes = () => (
  <div>
    <Switch>
      <Route exact path='/'                                     component={Home}/>
      <Route exact path='/info/about'                           render={(props) => <Wrap><About/>                           </Wrap>}/>
      <Route exact path='/info/terms'                           render={(props) => <Wrap><Terms/>                           </Wrap>}/>
      <Route exact path='/info/contact'                         render={(props) => <Wrap><Contact/>                         </Wrap>}/>
      <Route exact path='/info/professional'                    render={(props) => <Wrap><ProfessionalInfo/>                </Wrap>}/>
      <Route exact path='/info/reviewer'                        render={(props) => <Wrap><ReviewerInfo/>                    </Wrap>}/>
      <Route exact path='/professionals'                        render={(props) => <Wrap><Professionals         {...props}/></Wrap>}/>
      <Route exact path='/professional/:userId'                 render={(props) => <Wrap><ProfessionalContainer {...props}/></Wrap>}/>
      <Route exact path='/professional/:userId/validate-review' render={(props) => <Wrap><ValidateReview        {...props}/></Wrap>}/>
      <Route exact path='/registration/register'                render={(props) => <Wrap><Register              {...props}/></Wrap>}/>
      <Route       path='/registration/validate'                render={(props) => <Wrap><Validate              {...props}/></Wrap>}/>
      <Route exact path='/registration/reset-request'           render={(props) => <Wrap><ResetRequest                    /></Wrap>}/>
      <Route exact path='/registration/reset'                   render={(props) => <Wrap><ResetPassword         {...props}/></Wrap>}/>
      <Route                                                    component={PageNotFound}/>
    </Switch>
  </div>
);

const Wrap : SFC<{}> = (props) => (
  <div id='main-container' className='container'>
    <div className='row'>
      <div className='col-md-11'>
        <div className='row'>
          <div className='col-md-3 col-md-offset-0 col-lg-offset-1'>
            <div className='hidden-xs hidden-sm'><User/></div>
          </div>
          <div className='col-md-9 col-lg-8'>
            <div className='container-fluid'>
              {props.children}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
);

const PageNotFound = (props : RouteComponentProps<any>) => (
  <div>
    <h1>Page not found</h1>
    Invalid path: <code>{props.location.pathname}</code>
  </div>
);

export default Router;
