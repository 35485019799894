import * as React from 'react';
import { SFC } from 'react';
import { Link } from 'react-router-dom';

import * as Types from 'client/Types';
/*
TODO:
*/

interface PageLinkButtonRowProps {
  pageLinks : Types.PageLinks,
  pageIndex : number
}
const PageLinkButtonRow : SFC<PageLinkButtonRowProps> = ({pageLinks, pageIndex}) => (
  // Easy way to have different extent on xs screens.
  // TODO: fix PageLinkButtons computation to handle different extents for different screen sizes.
  <div>
    <div className='visible-xs-inline-block'>
      <PageLinkButtons pageLinks={pageLinks} pageIndex={pageIndex} nearPageExtent={0}/>
    </div>
    <div className='hidden-xs'>
      <PageLinkButtons pageLinks={pageLinks} pageIndex={pageIndex} nearPageExtent={2}/>
    </div>
  </div>
);

interface PageLinkButtonProps {
  nearPageExtent : number, // nearPageExtent: desired nr of visible page links adjacent to current page
  pageLinks : Types.PageLinks,
  pageIndex : number
}
// Including the first and last links, we always show 2*nearPageExtent+1+2 links (unless limited by nr of pages.)
// E.g. for a nearPageExtent of 1:
// (1) 2 3 4 ...
// 1 (2) 3 5 ...
// 1 2 (3) 4 ...
// 1 .. 3 (4) 5 ...
const PageLinkButtons : React.SFC<PageLinkButtonProps> =
        ({nearPageExtent, pageLinks, pageIndex}) => {

  const { previousPageLink, numberedPageLinks, nextPageLink } = pageLinks;
  const nrOfPages = numberedPageLinks.length;
  const nearPagesLeftIx = Math.max(
    0,
    Math.min(
      nrOfPages - 1 - (2 * nearPageExtent + 1), // guarantee minimum number of visible links when at the edge
      pageIndex - nearPageExtent
  ));
  const nearPagesRightIx = Math.min(
    nrOfPages - 1,
    Math.max(
      2 * nearPageExtent + 1, // guarantee minimum number of visible links when at the edge
      pageIndex + nearPageExtent
  ));
  const firstPageHref = nearPagesLeftIx !== 0 ? numberedPageLinks[0].href : null;
  const showingLeftEllipses = nearPagesLeftIx > 1;
  const nearPageHrefs = numberedPageLinks.slice(nearPagesLeftIx, nearPagesRightIx + 1).map(({href}) => href);
  const currentNearPageIx = pageIndex - nearPagesLeftIx;
  const showingRightEllipses = nearPagesRightIx < nrOfPages - 2;
  const lastPageHref = nearPagesRightIx !== nrOfPages - 1 ? numberedPageLinks[nrOfPages - 1].href : null;

  // NOTE: The current page will always be one of the nearPageRefs, so first and last page don't have an isCurrent prop
  return (
    <div className='page-links'>
      <PageLinkButton bType='previous-page' label='Vorige' href={previousPageLink ? previousPageLink.href : null}/>
      <div className='numbered-page-links'>
        { firstPageHref !== null &&
          <PageLinkButton bType='page-nr' label='1' href={firstPageHref}/>
        }
        {  showingLeftEllipses &&
          <div className='ellipses'>&hellip;</div>
        }
        { nearPageHrefs.map((href, i) => {
            const label = '' + (nearPagesLeftIx + i + 1);
            return <PageLinkButton bType='page-nr' label={label} href={href} key={label}
                                   isCurrent={i === currentNearPageIx}/>;
          })
        }
        {  showingRightEllipses &&
          <div className='ellipses'>&hellip;</div>
        }
        { lastPageHref !== null &&
          <PageLinkButton bType='page-nr' label={'' + nrOfPages} href={lastPageHref}/>
        }
      </div>
      <PageLinkButton bType='next-page' label='Volgende' href={nextPageLink ? nextPageLink.href : null}/>
    </div>
  );
};
type ButtonType = 'page-nr' | 'previous-page' | 'next-page';

const PageLinkButton : React.SFC<{bType : ButtonType, label : string, href : string | null, isCurrent? : boolean}> =
        ({bType, label, href, isCurrent}) => {
  const button = <div className={'page-link ' + bType +
                                 (href === null ? ' disabled' : '') + (isCurrent ? ' current' : '')
                                }>{label}</div>;
  return (
    href === null
    ? button
    : <Link to={href}>{button}</Link>
  );
};

export default PageLinkButtonRow;
