import * as React from 'react';
import { Component, Props } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link } from 'react-router-dom';

import * as Types from 'client/Types';
import * as Actions from 'client/redux/Actions';
/*
TODO:
- Combine with login modal
- Maybe find a method to do this without functions in redux state
*/
const opacityClass = (visibility : Types.ModalVisibility) => {
  switch (visibility) {
    case Types.ModalVisibility.Initial:
      return '';
    case Types.ModalVisibility.Show:
      return 'fade-in';
    case Types.ModalVisibility.Hide:
      return 'fade-out';
  }
};

interface ConfirmModalProps {
  confirmModalVisibility : Types.ModalVisibility,
  confirmModalTitle : string, confirmModalMessage : string,
  confirmModalAcceptText : string, confirmModalCancelText : string,
  confirmModalHandleAccept : () => void, confirmModalHandleCancel : () => void,
  dispatch : Actions.CombinedDispatch
}
const ConfirmModal : React.SFC<ConfirmModalProps> =
  ({confirmModalVisibility, ...props}) => (
  <div className={'modal-layer ' + opacityClass(confirmModalVisibility)}>
    { confirmModalVisibility === Types.ModalVisibility.Show && <ConfirmForm {...props}/> }
  </div>
);
interface ConfirmFormProps {
  confirmModalTitle : string, confirmModalMessage : string,
  confirmModalAcceptText : string, confirmModalCancelText : string,
  confirmModalHandleAccept : () => void, confirmModalHandleCancel : () => void,
  dispatch : Actions.CombinedDispatch;
}
interface ConfirmFormState {
}
class ConfirmForm extends Component<ConfirmFormProps, ConfirmFormState> {
  handleConfirmButton = () => {
    this.props.dispatch(Actions.setConfirmModalVisibility(Types.ModalVisibility.Hide));
    this.props.confirmModalHandleAccept();
  }

  handleCancelButton = () => {
    this.props.dispatch(Actions.setConfirmModalVisibility(Types.ModalVisibility.Hide));
    this.props.confirmModalHandleCancel();
  }

  render() {
    return (
      <div className='modal-element confirm-modal'>
        <div className='modal-element-header'>{ this.props.confirmModalTitle }</div>
        <div className='modal-element-message'>{ this.props.confirmModalMessage }</div>
        <form className='modal-element-buttons' name='confirmForm'>
          <input type='button' value={this.props.confirmModalAcceptText}
                 onClick={this.handleConfirmButton}
                 className='login-button btn btn-sm btn-default'/>{' '}
          <input type='button' value={this.props.confirmModalCancelText}
                 onClick={this.handleCancelButton}
                 className='login-button btn btn-sm btn-default'/>
        </form>
      </div>
    );
  }
}


const mapStateToProps = ({modal: {
    confirmModalVisibility, confirmModalTitle, confirmModalMessage, confirmModalAcceptText,
    confirmModalCancelText, confirmModalHandleAccept, confirmModalHandleCancel
  }} : Types.ReducerState) => ({
    confirmModalVisibility, confirmModalTitle, confirmModalMessage, confirmModalAcceptText,
    confirmModalCancelText, confirmModalHandleAccept, confirmModalHandleCancel
});

export default connect(mapStateToProps)(ConfirmModal);
