import * as React from 'react';
import { Component, Fragment, Props, SFC } from 'react';

import * as Actions from 'client/redux/Actions';
import * as Types from 'client/Types';
import { uploadAvatar } from 'client/Api';

interface ProfileAvatarProps {
  prof : Types.Professional,
  isAuthorized : boolean,
  dispatch : Actions.CombinedDispatch
}
export default class ProfileAvatar extends Component<ProfileAvatarProps, {}> {
  uploadAvatar = (evt : React.ChangeEvent<HTMLInputElement>) => {
    const fileList = evt.currentTarget.files;
    if (fileList !== null && fileList.length === 1) {
      uploadAvatar(this.props.prof.userId, fileList[0])
      .then((prof) => {
        this.props.dispatch(Actions.fetchProfessional(this.props.prof.userId));
        this.props.dispatch(Actions.fetchReviews(this.props.prof.userId));
        this.props.dispatch(Actions.syncAuth());
      })
      .catch((err) => {
        console.log('Error uploading avatar:', err);
      });
    }
  }

  render() {
    return (
      <div className='avatar-wrapper'>
        <img className='avatar' src={this.props.prof.avatar}/>
        { this.props.isAuthorized &&
          <Fragment>
            <input id='hidden-upload-button'
                    type='file'
                    disabled={false}
                    pattern='image/*'
                    accept='image/*'
                    onChange={this.uploadAvatar}
                    className='form-control'
            />
            <label htmlFor='hidden-upload-button' className='btn btn-default'>
              Profielfoto wijzigen
            </label>
          </Fragment> }
      </div>
    );
  }
}
