import * as React from 'react';
import { Component, Fragment, Props, SFC } from 'react';
import { connect, InferableComponentEnhancerWithProps } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import _uniq from 'lodash-es/uniq';

import * as shared from 'shared/shared';
import * as Actions from 'client/redux/Actions';
import * as Types from 'client/Types';
import * as Utils from 'util/Utils';
import { uploadAvatar } from 'client/Api';
import ProfileAvatar from './ProfileAvatar';
import NarrowDescription from './NarrowDescription';
import { PROF_TYPE_OTHER_VAL } from './NarrowDescription';
import WideDescription from './WideDescription';
import Reviews from 'components/reviews/Reviews';
/*
TODO:
*/

// Container takes care of matching url and dispatching fetch actions
export interface ProfessionalContainerProps extends RouteComponentProps<{ userId : string }> { // declares match, location, etc. props
  authUser : Types.Professional | null
  professionalsById : {id : string, prof : Types.Professional}[],
  reviewsByRevieweeId : {id : string, reviews : Types.Review[]}[],
  dispatch : Actions.CombinedDispatch
 }
class ProfessionalContainer extends Component<ProfessionalContainerProps, {}> {
  fetchProfessional = () => {
    const userId = this.props.match.params.userId;
    this.props.dispatch(Actions.fetchProfessional(userId));
    this.props.dispatch(Actions.fetchReviews(userId));
  }

  componentDidMount() {
    this.fetchProfessional();
  }
  componentDidUpdate(previousProps : ProfessionalContainerProps) {
    if (previousProps.match.params.userId !== this.props.match.params.userId) {
      this.fetchProfessional();
    }
    // Slightly hacky way to reload reviews on login/logout, to get/hide unaccepted reviews
    // TODO: Maybe keep viewed professional in redux as well.
    if (previousProps.authUser !== this.props.authUser) {
      this.props.dispatch(Actions.fetchReviews(this.props.match.params.userId)); // take userId from next, just in case
    }
  }

  render() {
    const prof = Utils.getProfessionalForId(this.props.professionalsById, this.props.match.params.userId);

    return (
      prof === null
      ? <div></div>
      : <Professional prof={prof}
                      authUser={this.props.authUser}
                      reviewsByRevieweeId={this.props.reviewsByRevieweeId}
                      dispatch={this.props.dispatch}/>
    );
  }
}

interface ProfessionalProps {
  prof : Types.Professional,
  authUser : Types.Professional | null
  reviewsByRevieweeId : {id : string, reviews : Types.Review[]}[],
  dispatch : Actions.CombinedDispatch
}
interface ProfessionalState {
  isEditing : boolean,
  isReviewing : boolean, // new review state is kept inside and posted from ReviewUnderConstruction component
  editedProf : Types.EditedProfessional | null,
  professionalTypeOther : string // for the text field in case professional type 'Anders:' is selected
}
class Professional extends Component<ProfessionalProps, ProfessionalState> {
  state : ProfessionalState = { // Adding this sig gives more informative errors
    isEditing: false,
    isReviewing: false,
    editedProf: null,
    professionalTypeOther: ''
  };

  handleStartEditing = () => {
    const prof = this.props.prof;
    const isOtherProfType = !shared.db.professionalTypeValues.includes(prof.professionalType);

    this.setState({
      isEditing: true,
      editedProf: {...prof,
        professionalType: isOtherProfType ? PROF_TYPE_OTHER_VAL : prof.professionalType,
        rate: prof.rate === null ? '' : '' + prof.rate,
        keywords: '' + prof.keywords.join(', ')
      },
      professionalTypeOther: isOtherProfType ? prof.professionalType : ''
    });
  }

  handleSetIsReviewing = (val : boolean) => {
    this.setState({
        isReviewing: val,
    });
  }

  handleSaveChanges = () => {
    if (this.state.editedProf && this.validateProfForm()) { // Will always be the case

      // If 'Anders:' is selected, take the value from professionalTypeOther
      const professionalType = this.state.editedProf.professionalType !== PROF_TYPE_OTHER_VAL
        ? this.state.editedProf.professionalType
        : this.state.professionalTypeOther;

      const rate = this.state.editedProf.rate !== undefined ? parseInt(this.state.editedProf.rate) : undefined;
      const keywords = _uniq(
        this.state.editedProf.keywords.split(',').map((keyword) => keyword.trim()).filter((keyword) => keyword !== '')
      );

      // add https if necessary
      const website = this.state.editedProf.website === undefined ? undefined :
        (this.state.editedProf.website.match(/^https?:\/\//) ? '' : 'https://') + this.state.editedProf.website;
      const linkedin = this.state.editedProf.linkedin === undefined ? undefined :
        (this.state.editedProf.linkedin.match(/^https?:\/\//) ? '' : 'https://') + this.state.editedProf.linkedin;

      const newProf = {...this.props.prof, ...this.state.editedProf, professionalType, rate, keywords, website, linkedin};
      this.props.dispatch(Actions.updateProfessional(newProf));
      this.setState({isEditing: false});
    }
  }

  handleCancelEditing = () => { // TODO: check for unsaved changes (also not implemented in Angular version)
    this.setState({isEditing: false});
  }

  setProfessionalTypeOtherHandler = (evt : React.ChangeEvent<HTMLInputElement>) => {
    this.setState({professionalTypeOther: evt.currentTarget.value});
  }

  updateEditedProfHandler : (key : string) => (evt : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => void = (key) => (evt) => {
    const newValue = evt.currentTarget.value;
    this.setState(({editedProf}) => (editedProf === null ? {editedProf: null /* won't happen */} : {
      editedProf: {...editedProf, [key]: newValue}
    }));
  }

  validateProfForm = () => {
    const formElt = document.forms['professionalForm' as any];
    const htmlValidity = formElt !== undefined && formElt.checkValidity();
    return this.state.editedProf !== null && htmlValidity &&
      (!this.state.editedProf.rate || /^\d+$/.test(this.state.editedProf.rate));
  }

  refreshProfessional = () => {
  }

  render() {
    const prof = this.props.prof;
    const isAdmin = this.props.authUser ? this.props.authUser.accessLevel === 'admin' : false;
    const isAuthorized = prof !== null && (isAdmin || (this.props.authUser !== null && this.props.authUser.userId === prof.userId));

    return prof === null ? <div></div> : (
      <div className='professional-profile'>
        <form name='professionalForm'> {/* TODO: when form is inside template, validation doesn't work, and since it can't be between flex container and child, we need it outside */}
          <div className='professional-description'>
            <ProfileAvatar prof={prof} isAuthorized={isAuthorized} dispatch={this.props.dispatch}/>
            {/* xs version of grade, >xs is in narrow-description. */}
            <div className='avg-grade visible-xs'>
              <div className='grade'>
                <span className='glyphicon glyphicon-star-empty'></span>{Utils.showGrade(prof.averageGrade)}
              </div>
              <div className='nr-of-reviews'>
                { prof.nrOfReviews === 0
                  ? '(0 reviews)'
                  : (prof.nrOfReviews === 1 ? '(1 review)' : `(${prof.nrOfReviews} reviews)`) }
              </div>
            </div>

            <div className='force-wrap visible-xs'></div>
            <div className='spacer hidden-xs'></div>

            <NarrowDescription prof={prof} editedProf={this.state.editedProf} isEditing={this.state.isEditing}
                               isAuthorized={isAuthorized}
                               updateEditedProfHandler={this.updateEditedProfHandler}
                               professionalTypeOther={this.state.professionalTypeOther}
                               setProfessionalTypeOtherHandler={this.setProfessionalTypeOtherHandler}/>
            <div className='force-wrap visible-xs'></div> {/* Necessary for Safari */}
            <WideDescription prof={prof} editedProf={this.state.editedProf} isEditing={this.state.isEditing}
                             updateEditedProfHandler={this.updateEditedProfHandler}/>

          </div>
        </form>
        <Reviews authUser={this.props.authUser}
                 prof={prof}
                 isEditing={this.state.isEditing}
                 isReviewing={this.state.isReviewing}
                 setIsReviewing={this.handleSetIsReviewing}
                 isProfFormValid={this.validateProfForm()}
                 handleStartEditing={this.handleStartEditing}
                 handleSaveChanges={this.handleSaveChanges}
                 handleCancelEditing={this.handleCancelEditing}
                 reviewsByRevieweeId={this.props.reviewsByRevieweeId}
                 dispatch={this.props.dispatch}/>
      </div>
    );
  }
}


const mapStateToProps = ({auth: {authUser}, professionals: {professionalsById}, reviews: {reviewsByRevieweeId}} : Types.ReducerState) => ({
  authUser,
  professionalsById,
  reviewsByRevieweeId
});

export default connect(mapStateToProps)(ProfessionalContainer);
