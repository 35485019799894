import * as React from 'react';
import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import _sortBy from 'lodash-es/sortBy';

import * as Utils from 'util/Utils';
import * as Types from 'client/Types';
import * as Actions from 'client/redux/Actions';
import * as Api from 'client/Api';
import Review from './Review';
import ReviewUnderConstruction from './ReviewUnderConstruction';

const Dotdotdot = require('react-dotdotdot');
/*
TODO:
- isEditing does not belong here
- accepting/commenting on reviews
- acceptReview

- missing edit ops
- create review + other missing stuff from professional.html
*/

interface ReviewsProps {
  authUser : Types.Professional | null,
  prof : Types.Professional,
  isEditing : boolean,
  isReviewing : boolean,
  setIsReviewing : (val : boolean) => void,
  isProfFormValid : boolean,
  handleStartEditing : () => void,
  handleSaveChanges : () => void,
  handleCancelEditing : () => void,
  reviewsByRevieweeId : {id : string, reviews : Types.Review[]}[],
  dispatch : Actions.CombinedDispatch
}
const Reviews : React.SFC<ReviewsProps> =
  ({authUser, prof, isEditing, isReviewing, setIsReviewing, isProfFormValid, handleStartEditing, handleSaveChanges, handleCancelEditing,
    reviewsByRevieweeId, dispatch}) => {
  const generateFakeReview = async () => {
    await Api.createFakeReview(prof.userId);
    dispatch(Actions.fetchProfessional(prof.userId)); // TODO: Can we refresh these dependencies more automatically?
    dispatch(Actions.fetchReviews(userId));
    if (authUser && authUser.userId === prof.userId) {
      dispatch(Actions.syncAuth());
    }
  };

  const isAdmin = authUser ? authUser.accessLevel === 'admin' : false;
  const isAuthorized = isAdmin || (authUser && authUser.userId === prof.userId);
  const isNotSelf = !(authUser && authUser.userId === prof.userId);
  const userId = prof.userId;
  const [rs] = reviewsByRevieweeId.filter(({id: pId, reviews}) => pId === userId);
  const reviews = rs ? rs.reviews : [];

  return (
    <div>
      <div className='review-list-header buttons'>
        <div className='review-label'>Reviews</div>
        <div className='flex-h-spacer'></div>
        {/* NOTE: watch width on xs when adding buttons (for admin, overflow is okay) */}
        { isAdmin &&
          <input type='button' className='btn btn-sm btn-default'
                 value='Genereer review'
                 onClick={() => generateFakeReview()}/> }
        { isNotSelf && !isReviewing && !isEditing &&
          <input type='button' className='btn btn-sm btn-default'
                 value={'Schrijf een review voor ' + prof.firstName}
                 onClick={() => setIsReviewing(true)}/> }

        { isEditing
        ? <Fragment>
            <input type='button' className='btn btn-sm btn-default'
                   value='Opslaan'
                   onClick={handleSaveChanges}
                   disabled={!isProfFormValid} />
            <input type='button' className='btn btn-sm btn-default'
                   value='Annuleren'
                   onClick={handleCancelEditing}/>
          </Fragment>
        : isAuthorized && !isReviewing &&
          <input type='button' className='btn btn-sm btn-default'
                  value='Profiel wijzigen'
                  onClick={handleStartEditing}/> }
      </div>

      { reviews && reviews.length === 0 && !isReviewing
        ? <div>{prof.firstName} heeft nog geen reviews ontvangen.</div>
        : <ol className='review-list'>
            { isReviewing &&
              <ReviewUnderConstruction prof={prof} authUser={authUser}
                                       setIsReviewing={setIsReviewing} dispatch={dispatch}/> }

            { !reviews.every((review) => review.isAccepted) &&
              <div className='unaccepted-reviews-label'>
                Er zijn ongeaccepteerde reviews.
              </div> }

            { _sortBy(reviews, ({isAccepted}) => isAccepted, ({date}) => date).map((review) =>
                <Review review={review} prof={prof} isAdmin={isAdmin} key={review.reviewId} authUser={authUser} dispatch={dispatch}/> ) }
          </ol> }
    </div>
  );
};

export default Reviews;
