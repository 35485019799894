import * as React from 'react'; // Always necessary in tsx (desugaring assumes React module object)
import { Provider } from 'react-redux';

import { store } from 'client/redux/InitializeStore';
import BuildInfoRibbon from 'components/BuildInfoRibbon';
import { buildInfo } from 'util/BuildInfo';
import Routes from './Routes';

// Google Analytics code snippet (https://www.google.com/analytics, select 'Google Analytics' from pull-down) adapted
// for TypeScript with a global declare and some any signatures (and two weird optional parameters `a` and `m`).
declare global { function ga(...args : any[]) : any; }
if (buildInfo.isProduction) {
  /* tslint:disable whitespace semicolon */
  (function(i : any, s : any, o : any, g : any, r : any, a? : any, m? : any){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
      (i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*(new Date() as any);a=s.createElement(o),
    m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
  })(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

  ga('create', 'UA-77656181-1', 'auto');
  ga('send', 'pageview');
  /* tslint:ensable whitespace semicolon */
}

export const App : React.SFC<{}> = () => (
  <Provider store={store}>
    <div>
      {/* <React.StrictMode> */}
        <BuildInfoRibbon/>
        <Routes/>
      {/* </React.StrictMode> */}
    </div>
  </Provider>
);

// Old test class for checking unnecessary remounting
// export class App extends React.Component<{}, {}> {
//   render() {
//     return <div onClick={() => {console.log('upd');this.forceUpdate(); }}><BuildInfoRibbon/><Routes/></div>;
//   }
// }
