import * as React from 'react';
import { Component, Props } from 'react';
import { connect, InferableComponentEnhancerWithProps } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Link, RouteComponentProps } from 'react-router-dom';
import * as qs from 'query-string';

import * as Actions from 'client/redux/Actions';
import * as Types from 'client/Types';
import * as Utils from 'util/Utils';
import PageLinkButtonRow from './PageLinkButtonRow';
/*
TODO:
*/

interface ProfessionalsProps extends RouteComponentProps<{}> { // declares match, location, etc. props ({} is url params type)
  authUser : Types.Professional | null,
  queriedString : string,
  queriedOffset : number,
  queriedLimit : number,
  queriedProfIds : (string | null)[];
  pageIndex : number,
  pagesTotal : number,
  resultsTotal : number,
  pageLinks : Types.PageLinks,
  professionalsById : {id : string, prof : Types.Professional}[];
  dispatch : Actions.CombinedDispatch;
}
interface ProfessionalsState {}
class Professionals extends Component<ProfessionalsProps, ProfessionalsState> {
  state : ProfessionalsState = { // Adding this sig gives more informative errors
  };

  fetchProfessionals() {
    const {q, offset, limit} = qs.parse(this.props.location.search);
    this.props.dispatch(Actions.fetchProfessionals(q ? '' + q : '', offset ? + offset : null, limit ? +limit : null));
    // use '' if url has no parameter "?q=.."
  }

  componentDidMount() {
    this.fetchProfessionals();
  }

  componentDidUpdate(previousProps : ProfessionalsProps) {
    if (previousProps.location.search !== this.props.location.search) {
      this.fetchProfessionals();
    }
  }

  setEditedShowId = (id : number | null) => {
    this.setState({editedShowId: id});
  }

  deleteProfessional = (prof : Types.Professional) => (evt : React.SyntheticEvent<HTMLDivElement>) => {
    evt.preventDefault();
    this.props.dispatch(Actions.showDialog(
      'Professional verwijderen',
      `Weet je zeker dat je het profiel van '${prof.firstName} ${prof.lastName}' wilt verwijderen? ` +
      `Dit kan niet ongedaan gemaakt worden`,
      'Verwijderen', 'Annuleren', () => this.props.dispatch(Actions.deleteProfessional(prof.userId)), () => {}
    ));
  }

  render() {
    const isAdmin = this.props.authUser ? this.props.authUser.accessLevel === 'admin' : false;
    const queriedStr = this.props.queriedString;
    const pageProfIds =
      this.props.queriedProfIds.slice(this.props.queriedOffset, this.props.queriedOffset + this.props.queriedLimit);
    const pageProfs = Utils.getProfessionalsForIds(this.props.professionalsById, pageProfIds);
    const nrOfPageProfs = pageProfs.length;

    return (
      <div className='professionals'>
        <h4>
          { this.props.pagesTotal > 1
            ? `Professionals ${this.props.queriedOffset + 1}-${this.props.queriedOffset + nrOfPageProfs} ` +
              `van ${this.props.resultsTotal}`
            : ( this.props.resultsTotal === 0 ? 'Geen professionals'
              : ( this.props.resultsTotal === 1 ? '1 professional'
                : this.props.resultsTotal + ' professionals')) +
              (queriedStr ? ` voor zoekterm  '${queriedStr}'` : '')
          }
        </h4>
        { this.props.pagesTotal > 1 &&
          <div className='search-page-links top'>
            <PageLinkButtonRow pageLinks={this.props.pageLinks} pageIndex={this.props.pageIndex}/>
          </div>
        }
        <ol className='professional-list'>
          { pageProfs.map((prof) =>
              <Link to={'/professional/' + prof.userId} key={prof.userId}>
                <li className='professional-inline'>
              <img className='avatar-thumbnail hidden-xs' src={prof.avatar_120x120} /* size 120 for retina support*//>

              <div className='professional-inline-details'>
                <div className='name'>
                  <img className='avatar-thumbnail-small visible-xs-inline-block' src={prof.avatar_120x120}/>
                  {prof.firstName} {prof.lastName}
                </div>
                <div className='job-title'>{prof.professionalType} - {prof.location}</div>
                <div>Reviews: {prof.nrOfReviews}</div>
              </div>
              <div className='flex-h-spacer'></div>
              { isAdmin &&
                <div className='delete-button glyphicon glyphicon-remove'
                     onClick={this.deleteProfessional(prof)}></div> }
              <div className='avg-grade'>{Utils.showGrade(prof.averageGrade)}</div>
            </li>
          </Link> )}
        </ol>
        { this.props.pagesTotal > 1 &&
          <div className='search-page-links bottom'>
            <PageLinkButtonRow pageLinks={this.props.pageLinks} pageIndex={this.props.pageIndex}/>
          </div>
        }
      </div>
    );
  }
}


const mapStateToProps = ({
        auth: {authUser},
        professionals: {
          queriedString, queriedOffset, queriedLimit, queriedProfIds,
          pageIndex, pagesTotal, resultsTotal, pageLinks, professionalsById
        }
      } : Types.ReducerState) =>
  ({
    authUser, queriedString, queriedOffset, queriedLimit, queriedProfIds,
    pageIndex, pagesTotal, resultsTotal, pageLinks, professionalsById
  });

export default connect(mapStateToProps)(Professionals);
