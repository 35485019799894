import * as Types from './Types';
import * as Fetch from 'util/Fetch';

const apiUrl = '/api';

// TODO: Error handling

export const getStats : () => Promise<{nrOfProfessionals : number, nrOfReviews : number}> = async () => {
  const response = await Fetch.jsonGet(`${apiUrl}/stats`);
  // console.log('/api/stats response:', response);
  return response;
};

export const loginUser : (username : string, password : string) => Promise<Types.Professional | null> =
  async (username : string, password : string) => {
    const authUser = await Fetch.jsonPost(`${apiUrl}/auth/login`, {username, password});
    // console.log('/api/auth/login response:', authUser);
    return authUser;
  };

export const logoutUser : () => Promise<void> = async () => {
  const response = await Fetch.plainGet(`${apiUrl}/auth/logout`);
  // console.log('/api/auth/logout response:', response);
};

export const getAuthUser : () => Promise<Types.Professional | null> = async () => {
  const {user: authUser} = await Fetch.jsonGet(`${apiUrl}/auth/user`);
  // console.log('/api/auth/user response:', authUser);
  return authUser;
};


export const getProfessionals : (query : string, offset : number | null, limit : number | null) =>
               Promise<Types.ProfQueryResult> =
               async (query, offset, limit) => {
  const response = await Fetch.jsonGet(
    `${apiUrl}/professionals?q=${query}` + (offset ? '&offset=' + offset : '') + (limit ? '&limit=' + limit : '')
  );
  return response;
};

export const getProfessional : (userId : string) => Promise<Types.Professional> = async (userId) => {
  return Fetch.jsonGet(`${apiUrl}/professionals/${userId}`); // TODO: Error handling
};

export const postProfessional : (prof : Types.BaseProfessional, password : string) => Promise<void> = async (prof, password) => {
  await Fetch.plainPost(`${apiUrl}/professionals`, {details: prof, password});
}; // Error is handled by caller, to alert user

export const putProfessional : (prof : Types.Professional) => Promise<void> = async (prof) => {
  await Fetch.plainPut(`${apiUrl}/professionals/${prof.userId}`, prof); // TODO: Error handling
};

export const validateUserId : (userId : string, validationCode : string) => Promise<Types.Professional> = async (userId, validationCode) => {
  return Fetch.jsonPatch(`${apiUrl}/professionals/${userId}/validate`, {validationCode}); // TODO: Error handling
};

export const deleteProfessional : (userId : string) => Promise<void> = async (userId) => {
  await Fetch.plainDelete(`${apiUrl}/professionals/${userId}`); // TODO: Error handling
};

export const uploadAvatar : (userId : string, file : File) => Promise<Types.Professional> = async (userId, file) => {
  const formData = new FormData();
  formData.append('avatarImage', file);
  const response = await Fetch.wrapHttpErrHandler(
    fetch(`/api/professionals/${userId}/avatar`, {
      method: 'PATCH', // NOTE: needs to be all-caps to prevent 400 Bad request..
      credentials: 'include',
      headers: { // NOTE: don't set Content-Type: multipart/form-data, because boundary will be missing
        'Accept': 'application/json'
      },
      body: formData
  }));
  return response.json();
};

export const getReviews : (userId : string) => Promise<Types.Review[]> = async (userId) => {
  return Fetch.jsonGet(`${apiUrl}/professionals/${userId}/reviews`); // TODO: Error handling
};

export const deleteReview : (reviewId : string) => Promise<void> = async (reviewId) => {
  await Fetch.plainDelete(`${apiUrl}/reviews/${reviewId}`); // TODO: Error handling
};

export const postReview : (revieweeId : string, review : Types.EditedReview) => Promise<{message : string}> = async (revieweeId, review) => {
  return Fetch.jsonPost(`${apiUrl}/reviews`, {revieweeId, ...review});
}; // Error is handled by caller, to alert user

export const validateReviewId : (reviewId : string, validationCode : string) => Promise<Types.Review> = async (reviewId, validationCode) => {
  return Fetch.jsonPatch(`${apiUrl}/reviews/${reviewId}/validate`, {validationCode}); // TODO: Error handling
};

export const acceptReviewId : (reviewId : string, revieweeComment : string) => Promise<Types.Review> = async (reviewId, revieweeComment) => {
  return Fetch.jsonPatch(`${apiUrl}/reviews/${reviewId}/accept`, {revieweeComment}); // TODO: Error handling
};

export const requestReset : (email : string) => Promise<void> = async (email) => {
  await Fetch.plainGet(`${apiUrl}/auth/reset-request?email=${encodeURIComponent(email)}`); // TODO: Error handling
};

export const resetPassword : (userId : string, validationCode : string, newPassword : string) => Promise<Types.Professional> = async (userId, validationCode, newPassword) => {
  return Fetch.jsonPatch(`${apiUrl}/professionals/${userId}/reset`, {validationCode, newPassword}); // TODO: Error handling
};

export const createFakeProfessional : () => Promise<Types.Professional> = async () => {
  return Fetch.jsonGet(`${apiUrl}/create-fake-professional`); // TODO: Error handling
};

export const createFakeReview : (revieweeId : string) => Promise<Types.Review> = async (revieweeId) => {
  return Fetch.jsonGet(`${apiUrl}/create-fake-review/${revieweeId}`); // TODO: Error handling
};

export const updateReviewStats : () => Promise<void> = async () => {
  await Fetch.plainGet(`${apiUrl}/update-review-stats`); // TODO: Error handling
};
