import * as Types from 'client/Types';

export enum ModalVisibility {
  Initial, // Initial state without animation
  Show,    // Will show fade-in animation
  Hide     // Will show fade-out animation
}
interface ModalStateI {
  loginModalVisibility : ModalVisibility,
  loginModalUsername : string,
  confirmModalVisibility : ModalVisibility,
  confirmModalTitle : string, confirmModalMessage : string,
  confirmModalAcceptText : string, confirmModalCancelText : string,
  confirmModalHandleAccept : () => void, confirmModalHandleCancel : () => void
}
export type ModalState = Readonly<ModalStateI>;

interface GlobalStateI {
  nrOfProfessionals : number,
  nrOfReviews : number
}
export type GlobalState = Readonly<GlobalStateI>;


interface AuthStateI {
  authUser : Types.Professional | null;
}
export type AuthState = Readonly<AuthStateI>;

interface ProfessionalsStateI {
  queriedString : string,
  queriedOffset : number, // Actual offset & limit as returned from server
  queriedLimit : number,  //
  queriedProfIds : (string | null)[], // Ordered list of professionals from current query
  pageIndex : number,
  pagesTotal : number,
  resultsTotal : number,
  pageLinks : Types.PageLinks,
  professionalsById : {id : string, prof : Types.Professional}[], // Cache of all queried professionals
  lastUpdated : Date | null // Probably useless
}
export type ProfessionalsState = Readonly<ProfessionalsStateI>;


interface ReviewsStateI {
  reviewsByRevieweeId : {id : string, reviews : Types.Review[]}[],
  lastUpdated : Date | null
}
export type ReviewsState = Readonly<ReviewsStateI>;
// export type NotificationType = 'Error' | 'Warning' | 'Info';

// // Unfortunately shadows Notification from browser desktop-notification API, so confusing errors when forgetting import.
// export interface Notification {
//   type : NotificationType,
//   message : string,
//   timestamp : Date
// }

// interface NotifierStateI {
//   notifications : Notification[]
// }
// export type NotifierState = Readonly<NotifierStateI>;

interface ReducerStateI {
  modal : ModalState,
  global : GlobalState,
  auth : AuthState,
  professionals : ProfessionalsState
  reviews : ReviewsState
}

export type ReducerState = Readonly<ReducerStateI>;
// // ReducerState is probably modified by redux, but that's outside of typescript scope (readonly is on type-level only)
// // Note: Readonly is shallow, so maybe it's not worth the extra type.
