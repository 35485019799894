import * as React from 'react';
import { Component, Fragment } from 'react';
import { Link } from 'react-router-dom';
import _random from 'lodash-es/random';
import _range from 'lodash-es/range';

import { buildInfo } from 'util/BuildInfo';
import * as shared from 'shared/shared';
import * as Utils from 'util/Utils';
import * as Types from 'client/Types';
import * as Actions from 'client/redux/Actions';
import * as Api from 'client/Api';
import { Select } from 'components/Select';

/*
TODO:
*/

interface ReviewUnderConstructionProps {
  prof : Types.Professional
  authUser : Types.Professional | null,
  setIsReviewing : (val : boolean) => void,
  dispatch : Actions.CombinedDispatch
}
interface ReviewUnderConstructionState {
  editedReview : Types.EditedReview
}
class ReviewUnderConstruction extends Component<ReviewUnderConstructionProps, ReviewUnderConstructionState> {
  state : ReviewUnderConstructionState = { // Adding this sig gives more informative errors
    editedReview: {
      email:     this.props.authUser !== null ? this.props.authUser.email : '',
      firstName: this.props.authUser !== null ? this.props.authUser.firstName : '',
      lastName:  this.props.authUser !== null ? this.props.authUser.lastName : '',

      jobTitle: '',
      relationToProfessional: '',

      resultGrade:         7,
      collaborationGrade:  7,
      methodGrade:         7,
      recommendationGrade: 7,
      reviewText: ''
    }
  };

  updateEditedReviewHandler : (key : string) => (evt : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => void = (key) => (evt) => {
    const newValue = evt.currentTarget.value;
    this.setState(({editedReview}) => ({
      editedReview: {...editedReview, [key]: newValue}
    }));
  }

  handleSetGradeForKey : (key : string) => (newGrade : number) => () => void =
    (key) => (newGrade) => () => {
      this.setState(({editedReview}) => ({
        editedReview: {...editedReview, [key]: newGrade}
      }));
  }

  handleAutofill : () => void = () => {
    const credentials = this.props.authUser !== null ? {} : {
      firstName: 'Cave',
      lastName: 'Johnson',
      email: 'martijn+cave@oblomov.com'
    };

    const fakeReview = {
        jobTitle: 'Professioneel reviewer',
        relationToProfessional: shared.db.relationToProfessionalValues[1],
        resultGrade:         _random(5, 10),
        collaborationGrade:  _random(5, 10),
        methodGrade:         _random(5, 10),
        recommendationGrade: _random(5, 10),
        reviewText: 'Repellendus qui et tempora et recusandae illum tenetur repellat culpa. Sed occaecati reprehenderit quia. Odio consequatur laudantium. Quis optio iusto autem totam corrupti est. Quo eos ex sequi quasi et sit cum facere cupiditate. Tempora consequatur sed ipsum impedit.\nModi quae distinctio similique deleniti accusantium excepturi cupiditate necessitatibus est. Vero voluptatibus debitis non eos dolorum et. Enim suscipit dolor voluptatibus natus a distinctio voluptatem. Tenetur et facere sit.',
    };

    this.setState({editedReview: {...credentials, ...fakeReview}});
  }

  handleSubmitReview : () => void = () => {
    Api.postReview(this.props.prof.userId, this.state.editedReview)
    .then(({message}) => {
      alert(message);
      this.props.setIsReviewing(false);
    }).catch((err) => {
      // TODO: if error is because of terminated session, offer login button (requires errors with more info)
      alert(err);
      this.props.setIsReviewing(false);
    });
 }

  handleCancel = () => { // TODO: check for unsaved changes (also not implemented in Angular version)
    this.props.setIsReviewing(false);
  }

  validateReview = () => {
    const isAuth = this.props.authUser !== null;

    const {
      firstName, lastName, email,
      relationToProfessional, reviewText
    } = this.state.editedReview;
    const requiredStrings =
      [relationToProfessional, reviewText] // grades are always set, so no need to check
      .concat(isAuth ? [] : [firstName, lastName, email].map((strOrUdf) => strOrUdf ? strOrUdf : ''));
    const isValid = requiredStrings.every((str : string) => str !== '');
    return isValid;
  }

  render() {
    const {prof} = this.props;
    const isAuth = this.props.authUser !== null;
    const {resultGrade, collaborationGrade, methodGrade, recommendationGrade} = this.state.editedReview;
    const totalGrade = (resultGrade + collaborationGrade + methodGrade + recommendationGrade) / 4;

    return (
       <form name='reviewForm' className='review-under-construction'>
        <div className='section-heading'>Details reviewer:</div>
        <div className='reviewer-details'>
          <table className='property-list'><tbody>
            <tr>
              <td className='prop-label'>Naam</td>
              <td className='prop-value full-name'>
                <input type='text' className='first-name input-sm form-control'
                       value={this.props.authUser !== null ? this.props.authUser.firstName : this.state.editedReview.firstName}
                       onChange={this.updateEditedReviewHandler('firstName')}
                       placeholder='Voornaam' disabled={isAuth}/>
                <input type='text' className='last-name input-sm form-control'
                       value={this.props.authUser !== null ? this.props.authUser.lastName : this.state.editedReview.lastName}
                       onChange={this.updateEditedReviewHandler('lastName')}
                       placeholder='Achternaam' disabled={isAuth}/>
              </td>
            </tr>
            <tr>
              <td className='prop-label'>E-mail</td>
              <td className='prop-value full-name'>
                <input type='email' className='input-sm form-control'
                       value={this.props.authUser !== null ? this.props.authUser.email : this.state.editedReview.email}
                       onChange={this.updateEditedReviewHandler('email')}
                       disabled={isAuth}/>
              </td>
            </tr>
            <tr>
              <td className='prop-label'>Functie</td>
              <td className='prop-value'>
                <input type='text' className='input-sm form-control'
                       value={this.state.editedReview.jobTitle} onChange={this.updateEditedReviewHandler('jobTitle')}
                       placeholder='Functietitel tijdens samenwerking (optioneel)'/>
              </td>
            </tr>
            <tr>
              <td className='prop-label'>Relatie&nbsp;tot&nbsp;{prof.firstName}</td>
              <td className='prop-value'>
                <Select options={shared.db.relationToProfessionalValues}
                        value={this.state.editedReview.relationToProfessional}
                        onChange={this.updateEditedReviewHandler('relationToProfessional')}/>
              </td>
            </tr>
          </tbody></table>
        </div>
        <div className='section-heading'>Details review:</div>
        <div className='review-details'>
          <div className='grades'>
            <div className='form-horizontal'>
              <GradeInput label='Resultaat'
                          grade={resultGrade} handleSetGrade={this.handleSetGradeForKey('resultGrade')}/>
              <GradeInput label='Samenwerking'
                          grade={collaborationGrade} handleSetGrade={this.handleSetGradeForKey('collaborationGrade')}/>
              <GradeInput label='Aanpak'
                          grade={methodGrade} handleSetGrade={this.handleSetGradeForKey('methodGrade')}/>
              <GradeInput label='Aanbeveling'
                          grade={recommendationGrade} handleSetGrade={this.handleSetGradeForKey('recommendationGrade')}/>
            </div>
            <div className='flex-h-spacer'></div>
            <div className='labeled-total-grade'>beoordeling
              <div className='total-grade'>{ Utils.showGrade(totalGrade) }</div>
            </div>
          </div>

          <textarea className='review-text form-control'
                    value={this.state.editedReview.reviewText} onChange={this.updateEditedReviewHandler('reviewText')}
                    rows={4} placeholder='Toelichting op review.'></textarea>
          <div className='buttons'>
            <div className='flex-h-spacer'></div>
            { !buildInfo.isProduction &&
              <input type='button' className='fill-button btn btn-sm btn-default' value='Fill'
                     onClick={this.handleAutofill}/> }
            <input type='button' className='post-button btn btn-sm btn-default' value='Post'
                   onClick={this.handleSubmitReview} disabled={!this.validateReview()}/>
            <input type='button' className='cancel-button btn btn-sm btn-default' value='Cancel'
                   onClick={this.handleCancel}/>
          </div>
        </div>
      </form>
    );
  }
}

// Note: There's a lot of rerendering with these higher order functions, but for 4 grades the cost is negligible
interface GradeInputProps {
  label : string,
  grade : number,
  handleSetGrade : (newGrade : number) => (evt : React.MouseEvent<HTMLSpanElement>) => void
}
const GradeInput : React.SFC<GradeInputProps> = ({label, grade, handleSetGrade}) => (
  <div className='form-group'>
    <div className='col-sm-3 grade-label'>{label}</div>
    <div className='col-sm-9 grade-input'>
      { _range(1, 11).map((n) => <span className={'glyphicon glyphicon-star' + (n > grade ? '-empty' : '')} 
                                      onClick={handleSetGrade(n)} key={n}/>) }
        <span className='grade'>{grade}</span>
    </div>
    <div className='col-sm-2'></div> {/* TODO: why does this fix the layout (adds spacing between label & grade) */}
  </div>
);

export default ReviewUnderConstruction;
