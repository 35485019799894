const jsonHeaders = {
  'Content-Type': 'application/json'
};

// Fetch only throws an exception on a network error, and not on an http error (though it does log the error on
// console.error) To get an exception, we throw manually if the response status code is >= 400
export const wrapHttpErrHandler : (doFetch : Promise<Response>) => Promise<Response> = async (doFetch) => {
  const response = await doFetch;
  if (response.status >= 400) {
    const serverError = await response.text(); // Retrieve server error message from body
    throw new Error(response.status /* eg. 403 */ + ' ' + response.statusText /* eg. forbidden */ + ':\n' + serverError);
  }
  return response;
};

export const plainGet = async (url : string) : Promise<Response> => {
  // console.log('GET ' + url);
  return wrapHttpErrHandler(
    fetch(url, {
        headers: jsonHeaders,
        credentials: 'include',
        method: 'GET'
  }));
};

export const plainPost = async (url : string, data : any) : Promise<Response> => {
  // console.log('POST ' + url);
  return wrapHttpErrHandler(
    fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: jsonHeaders,
    body: JSON.stringify(data)
  }));
};

export const plainPut = async (url : string, data : any) : Promise<Response> => {
  // console.log('PUT ' + url);
  return wrapHttpErrHandler(
    fetch(url, {
      method: 'PUT',
      credentials: 'include',
      headers: jsonHeaders,
      body: JSON.stringify(data)
  }));
};

export const plainPatch = async (url : string, data : any) : Promise<Response> => {
  // console.log('PATCH ' + url);
  return wrapHttpErrHandler(
    fetch(url, {
      method: 'PATCH', // For patch only, method needs to be all-caps to prevent 400 Bad request..
      credentials: 'include',
      headers: jsonHeaders,
      body: JSON.stringify(data)
  }));
};

export const plainDelete = async (url : string) : Promise<Response> => {
  // console.log('DELETE ' + url);
  return wrapHttpErrHandler(
    fetch(url, {
      headers: jsonHeaders,
      credentials: 'include',
      method: 'DELETE'
  }));
};

export const jsonGet = async (url : string) => {
  const response = await plainGet(url);
  return response.json();
};

export const jsonPost = async (url : string, data : any) => {
  const response = await plainPost(url, data);
  return response.json();
};

export const jsonPut = async (url : string, data : any) => {
  const response = await plainPut(url, data);
  return response.json();
};

export const jsonPatch = async (url : string, data : any) => {
  const response = await plainPatch(url, data);
  return response.json();
};

export const jsonDelete = async (url : string) => {
  const response = await plainDelete(url);
  return response.json();
};
