import * as React from 'react';
import { Component, Props } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators} from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { Link, RouteComponentProps, withRouter } from 'react-router-dom';
import * as History from 'history';

import * as Types from 'client/Types';
import * as Actions from 'client/redux/Actions';
/*
TODO:
- Check on phone
- Handle timeout to sync with server logout (check if sync extends session, and whether timeout extended on other
  requests)
*/
const opacityClass = (visibility : Types.ModalVisibility) => {
  switch (visibility) {
    case Types.ModalVisibility.Initial:
      return '';
    case Types.ModalVisibility.Show:
      return 'fade-in';
    case Types.ModalVisibility.Hide:
      return 'fade-out';
  }
};

interface LoginModalProps extends RouteComponentProps<{}> { // declares match, location, etc. props ({} is url params type)
  loginModalVisibility : Types.ModalVisibility,
  loginModalUsername : string,
  authUser : Types.Professional | null,
  dispatch : ThunkDispatch<Types.ReducerState, void, Actions.RootAction>
}
const LoginModal : React.SFC<LoginModalProps> =
  ({loginModalVisibility, ...props}) => (
  <div className={'modal-layer ' + opacityClass(loginModalVisibility)}>
    { loginModalVisibility === Types.ModalVisibility.Show && <LoginForm {...props}/> }
  </div>
); // Extra indirection to unmount LoginForm on hide, which easily resets state on show, without needing redux.

interface LoginFormProps {
  loginModalUsername : string,
  authUser : Types.Professional | null,
  dispatch : ThunkDispatch<Types.ReducerState, void, Actions.RootAction>,
  history : History.History
}
interface LoginFormState {
  password : string,
  loginErrorMsg : string
}
class LoginForm extends Component<LoginFormProps, LoginFormState> {
  state = {
    password: '',
    loginErrorMsg: ''
  };

  usernameInputElt : HTMLInputElement | null;
  passwordInputElt : HTMLInputElement | null;

  // Also triggered by enter key in text fields, but only when submit button is enabled, so no extra check needed.
  onSubmit = (evt : React.FormEvent<HTMLFormElement>) => {
    evt.preventDefault();
    this.props.dispatch(Actions.login(this.props.loginModalUsername, this.state.password))
    .then((authUser) => {
      if (authUser !== null) {
        this.dismissDialog();
        this.props.history.push(`/professional/${authUser.userId}`);
      } else {
        this.setState({loginErrorMsg: 'Ongeldige gebruikersnaam of wachtwoord.'});
      }
    });
  }

  dismissDialog = () => {
    this.props.dispatch(Actions.setLoginModalVisibility(Types.ModalVisibility.Hide));
  }

  render() {
    const hasUsername = this.props.loginModalUsername !== '';
    return (
      <div>
        { this.props.authUser === null
          ?   <form id='login-service-modal' className='modal-element' name='loginForm' onSubmit={this.onSubmit}>
                <div className='form-group'>
                  <input type='text'
                         placeholder='Gebruikersnaam'
                         value={this.props.loginModalUsername}
                         autoFocus={!hasUsername}
                         onChange={(e) => this.props.dispatch(Actions.setLoginModalUsername(e.currentTarget.value))}
                         autoCorrect='off' autoCapitalize='off'
                         autoComplete='username'
                         className='input-sm form-control'
                  />
                </div>
                <div className='form-group'>
                  <input type='password'
                         placeholder='Wachtwoord'
                         value={this.state.password}
                         autoFocus={hasUsername}
                         onChange={(e) => this.setState({password: e.currentTarget.value})}
                         autoCorrect='off' autoCapitalize='off'
                         autoComplete='current-password'
                         className='input-sm form-control'
                  />
                </div>
                <div className='form-group login-error'>{this.state.loginErrorMsg}</div>
                <input type='submit' value='Inloggen'
                       disabled={this.props.loginModalUsername === '' || this.state.password === ''}
                       className='login-button btn btn-sm btn-default'/>{' '}
                <input type='button' value='Annuleren'
                       onClick={this.dismissDialog}
                       className='login-button btn btn-sm btn-default'/>
                <div className='password-reset' onClick={this.dismissDialog}>
                  <Link to='/registration/reset-request'>Wachtwoord<br/>vergeten</Link>
                </div>
              </form>
          : <input type='button' onClick={() => this.props.dispatch(Actions.logout())} value='Logout'/> }
      </div>
    );
  }
}


const mapStateToProps = ({modal: {loginModalVisibility, loginModalUsername}, auth: {authUser}} : Types.ReducerState) => ({
  loginModalVisibility,
  loginModalUsername,
  authUser
});

export default withRouter(connect(mapStateToProps)(LoginModal));
