import * as React from 'react';
import { Component, Fragment } from 'react';
import { Link, LinkProps } from 'react-router-dom';

import * as Utils from 'util/Utils';
import * as Types from 'client/Types';
import * as Actions from 'client/redux/Actions';
import * as Api from 'client/Api';

const Dotdotdot = require('react-dotdotdot');
/*
TODO:
- Add reject-review functionality
*/

// Only wrap in a link if props.to is not falsy
const LinkIfNonEmpty : React.SFC<LinkProps> = (props) => (
  props.to ? <Link {...props}/> : <Fragment>{props.children}</Fragment>
);

interface ReviewProps {
  prof : Types.Professional,
  review : Types.Review,
  isAdmin : boolean,
  authUser : Types.Professional | null,
  dispatch : Actions.CombinedDispatch
}
interface ReviewState {
  isExpanded : boolean,
  comment : string // Only for comment text area in unaccepted review
}
class Review extends Component<ReviewProps, ReviewState> {
  state : ReviewState = { // Adding this sig gives more informative errors
    isExpanded: false,
    comment: ''
  };

  expandReview = (e : React.SyntheticEvent<any>) => {
    this.setState({isExpanded: true});
  }

  hideReview = (e : React.SyntheticEvent<any>) => {
    this.setState({isExpanded: false});
  }

  handleChangeComment = (evt : React.ChangeEvent<HTMLTextAreaElement>) => {
    this.setState({comment: evt.currentTarget.value});
  }

  acceptReview = (e : React.SyntheticEvent<any>) => {
    this.props.dispatch(Actions.acceptReviewId(this.props.review.reviewId, this.state.comment));
  }

  deleteReview = (review : Types.Review) => (evt : React.SyntheticEvent<HTMLDivElement>) => {
    evt.preventDefault();
    this.props.dispatch(Actions.showDialog(
      'Review verwijderen',
      `Weet je zeker dat je de review van '${review.firstName} ${review.lastName}' d.d. ` +
      `${Utils.showDate(review.date)} wilt verwijderen? ` +
      `Dit kan niet ongedaan gemaakt worden`,
      'Verwijderen', 'Annuleren', async () => {
        await this.props.dispatch(Actions.deleteReview(review.reviewId));
        this.props.dispatch(Actions.fetchProfessional(this.props.prof.userId));  // TODO: Can we refresh these dependencies more automatically?
        this.props.dispatch(Actions.fetchReviews(this.props.prof.userId));
        if (this.props.authUser && this.props.authUser.userId === this.props.prof.userId) {
          this.props.dispatch(Actions.syncAuth());
        }
      }, () => {}
    ));
  }

  render() {
    const {prof, review, isAdmin} = this.props;

    return (
        <li className={Utils.objectToClassName({
                        review: true,
                        unaccepted: !review.isAccepted,
                        expanded: !review.isAccepted || this.state.isExpanded
                       })}>
          <div>
            <LinkIfNonEmpty to={review.reviewerId ? '/professional/' + review.reviewerId : ''}>
              <div className='professional-inline'>
                <img className='avatar-thumbnail hidden-xs' src={review.avatar_120x120}/>
                <div className='professional-inline-details'>
                  <div className='name'>
                    <img className='avatar-thumbnail-small visible-xs-inline-block' src={review.avatar_120x120}/>
                    {review.firstName} {review.lastName}
                  </div>
                  <div className='job-title' >{review.jobTitle}</div>
                  <div className='date' >{Utils.showDate(review.date)}</div>
                </div>
                <div className='flex-h-spacer'></div>
                { isAdmin &&
                  <div className='delete-button glyphicon glyphicon-remove'
                     onClick={this.deleteReview(review)}></div> }
                { !this.state.isExpanded &&
                  <div className='labeled-total-grade'>
                    <span className='hidden-xs'>beoordeling</span>{' '}
                    <div className='total-grade'>{Utils.showGrade(review.grade)}</div>
                  </div> }
              </div>
            </LinkIfNonEmpty>
          </div>
          <table className='property-list specific-grades'>
            <tbody>
              <tr><td className='prop-label'>Relatie</td><td className='prop-value'>{review.relationToProfessional}</td></tr>
            </tbody>
          </table>

          <div className='section-heading'>Review:</div>
          <div className='grades'>
            <table className='property-list specific-grades'>
              <tbody>
                <tr><td className='prop-label'>Resultaat</td><td className='prop-value'>{review.resultGrade}</td></tr>
                <tr><td className='prop-label'>Samenwerking</td><td className='prop-value'>{review.collaborationGrade}</td></tr>
                <tr><td className='prop-label'>Aanpak</td><td className='prop-value'>{review.methodGrade}</td></tr>
                <tr><td className='prop-label'>Aanbeveling</td><td className='prop-value'>{review.recommendationGrade}</td></tr>
              </tbody>
            </table>
            <div className='flex-h-spacer'></div>
            { this.state.isExpanded &&
              <div className='labeled-total-grade'>
                beoordeling{' '}
                <div className='total-grade'>{Utils.showGrade(review.grade)}</div>
              </div> }
          </div>
          <div className='section-heading'>Toelichting:</div>
          <div className='text-and-expand-button'>
            { !this.state.isExpanded && review.isAccepted &&
              <input type='button' className='accept-button btn btn-sm btn-default'  value='Lezen'
                    onClick={this.expandReview}/> }
            <div className='review-text'>
              <Dotdotdot clamp={this.state.isExpanded || !review.isAccepted ? Number.MAX_SAFE_INTEGER : 2}>{review.reviewText}</Dotdotdot>
            </div>
          </div>
          <div className='reviewee-response'>
            { review.isAccepted
            ? <div className='reviewee-comment'>
                { review.revieweeComment &&
                  <span>
                    <span className='comment-header'>Reactie:</span>{' '}
                    <span className='comment-label'>{prof.firstName}</span>{' '}
                    <span className='date'>({Utils.showDate(review.revieweeComment.date)})</span>
                    <div className='comment'>{review.revieweeComment.text}</div>
                  </span> }
                <div className='buttons'>
                  <div className='flex-h-spacer'></div>
                  <input type='button' className='btn btn-default' value='Review sluiten'
                         onClick={this.hideReview}/>
                </div>
              </div>
            : <div>
                <div className='comment-label'>Jouw reactie op deze review:</div>
                <textarea className='review-text form-control'
                          value={this.state.comment} onChange={this.handleChangeComment}
                          rows={3} placeholder='Optionele reactie'></textarea>
                <input type='button' className='accept-button btn btn-sm btn-default' value='Accepteren'
                       onClick={this.acceptReview}/>
              </div> }
          </div>
        </li>
    );
  }
}

export default Review;
