import * as React from 'react';
import { Component, Fragment, Props, SFC } from 'react';

import * as shared from 'shared/shared';
import * as Types from 'client/Types';
import * as Utils from 'util/Utils';
import { Select } from 'components/Select';

export const PROF_TYPE_OTHER_VAL = '___OTHER_VALUE';
// Value of professional-type selector when 'Anders:' is selected (and professional type is kept in professionalTypeOther)

const NarrowDescription : SFC<{
        isAuthorized : boolean,
        prof : Types.Professional,
        editedProf : Types.EditedProfessional | null,
        isEditing : boolean,
        updateEditedProfHandler : (key : string) => (evt : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => void
        professionalTypeOther : string,
        setProfessionalTypeOtherHandler : (evt : React.ChangeEvent<HTMLInputElement>) => void
      }> = ({prof, editedProf, isAuthorized, isEditing, updateEditedProfHandler, professionalTypeOther, setProfessionalTypeOtherHandler}) => (
  <div className='narrow-description' data-is-editing={isEditing ? true : undefined}>
    { !isEditing
    ? <NarrowDescriptionView prof={prof} isAuthorized={isAuthorized}/>
    : ( editedProf !== null &&
        <NarrowDescriptionEdit editedProf={editedProf}
                               updateEditedProfHandler={updateEditedProfHandler}
                               professionalTypeOther={professionalTypeOther}
                               setProfessionalTypeOtherHandler={setProfessionalTypeOtherHandler}/> ) }
  </div>
);

const NarrowDescriptionView : SFC<{prof : Types.Professional, isAuthorized : boolean}> =
        ({prof, isAuthorized}) => (
  <Fragment>

    <div className='full-name'>{prof.firstName} {prof.lastName}</div>
    <div className='professional-type'>{prof.professionalType}</div>
    <div className='keywords'>
      { prof.keywords.map((keyword) => <span key={keyword}><span className='keyword'>{keyword}</span>{' '}</span>) }
    </div>

    <table className='property-list'>
      <tbody>
        <tr><td className='prop-label'>Gebruikersnaam</td>
            <td className='prop-value username'>{prof.username}</td></tr>
        <tr><td className='prop-label'>Locatie</td><td className='prop-value location'>{prof.location}</td></tr>
        <tr><td className='prop-label'>Dienstverband</td><td className='prop-value employment'>{prof.employment}</td></tr>
        <tr><td className='prop-label'>Ervaringsniveau</td><td className='prop-value experienceLevel'>{prof.experienceLevel}</td></tr>
        <tr><td className='prop-label'>Beschikbaarheid</td><td className='prop-value availability'>{prof.availability}</td></tr>
        <tr><td className='prop-label'>Tarief</td><td className='prop-value rate'>{prof.rate ? prof.rate : '-'}</td></tr>
        <tr><td className='prop-label'>Externe links</td><td className='prop-value'>
          { (!prof.linkedin && !prof.website) && <span>-</span> }
          { prof.website && <a className='website' href={prof.website}>website</a> }
          { prof.linkedin && prof.website && <span>, </span> }
          { prof.linkedin &&
            <div className='linkedin-logo'>
              <a href={prof.linkedin}>Linked<span className='linkedin-in'>In</span></a>
            </div> }
        </td></tr>
        { isAuthorized &&
          <tr><td className='prop-label'>E-mail</td><td className='prop-value email'>{prof.email}</td></tr> }
      </tbody>
    </table>

    {/* >xs version of grade, xs version is next to avatar.  TODO: Share code*/}
    <div className='avg-grade hidden-xs'>
      <div className='grade'>
        <span className='glyphicon glyphicon-star-empty'></span>{Utils.showGrade(prof.averageGrade)}
      </div>
      <div className='nr-of-reviews'>
        { prof.nrOfReviews === 0
          ? '(0 reviews)'
          : (prof.nrOfReviews === 1 ? '(1 review)' : `(${prof.nrOfReviews} reviews)`) }
      </div>
    </div>
  </Fragment>
);
export const NarrowDescriptionEdit : SFC<{
        editedProf : Types.EditedProfessional,
        updateEditedProfHandler : (key : string) => (evt : React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement | HTMLSelectElement>) => void,
        professionalTypeOther : string,
        setProfessionalTypeOtherHandler : (evt : React.ChangeEvent<HTMLInputElement>) => void
      }> = ({editedProf, updateEditedProfHandler, professionalTypeOther, setProfessionalTypeOtherHandler}) => (
  <Fragment>
    <table className='property-list'><tbody>
      <tr>
        <td className='prop-label'>Naam</td>
        <td className='prop-value full-name'>
          <input type='text' required className='first-name input-sm form-control'
                  value={editedProf.firstName} onChange={updateEditedProfHandler('firstName')}
                  placeholder='Voornaam'/>{' '}
          <input type='text' required className='last-name input-sm form-control'
                  value={editedProf.lastName} onChange={updateEditedProfHandler('lastName')}
                  placeholder='Achternaam'/></td>
      </tr>
      <tr>
        <td className='prop-label'>Type</td>
        <td className='prop-value'>
          <div className='professional-type'>
            <Select options={shared.db.professionalTypeValues.map((v) => ({val: v, label: v}))
                             .concat([{val: PROF_TYPE_OTHER_VAL, label: 'Anders'}])}
                    value={editedProf.professionalType} onChange={updateEditedProfHandler('professionalType')}/>
            { editedProf.professionalType === PROF_TYPE_OTHER_VAL &&
              <input type='text' required className='input-sm form-control'
                     value={professionalTypeOther} onChange={setProfessionalTypeOtherHandler}/> }
          </div>
        </td>
      </tr>
      <tr>
        <td className='prop-label'>Locatie</td>
        <td className='prop-value location'>
          <input type='text' required className='input-sm form-control'
                  value={editedProf.location} onChange={updateEditedProfHandler('location')}/>
        </td>
      </tr>
    </tbody></table>
    <table className='property-list'><tbody>
      <tr>
        <td className='prop-label'>Dienstverband</td>
        <td className='prop-value employment'>
          <Select options={shared.db.employmentValues} required
                  value={editedProf.employment} onChange={updateEditedProfHandler('employment')}/>
        </td>
      </tr>
      <tr>
        <td className='prop-label'>Ervaringsniveau</td>
        <td className='prop-value experience-level'>
          <Select options={shared.db.experienceLevelValues} required
                  value={editedProf.experienceLevel} onChange={updateEditedProfHandler('experienceLevel')}/>
        </td>
      </tr>
      <tr>
        <td className='prop-label'>Beschikbaarheid</td>
        <td className='prop-value'>
          <Select options={shared.db.availabilityValues} required
                  value={editedProf.availability} onChange={updateEditedProfHandler('availability')}/>
        </td>
      </tr>
    </tbody></table>
    <table className='property-list'><tbody>
      <tr>
        <td className='prop-label'>Tarief</td>
        <td className='prop-value'>
          <input type='text' className='input-sm form-control rate'
                  value={editedProf.rate} onChange={updateEditedProfHandler('rate')}
                  placeholder='Bedrag in euros (optioneel)'/>
        </td>
      </tr>
      <tr>
        <td className='prop-label'>E-mail</td>
        <td className='prop-value email'>
          <input type='email' required className='input-sm form-control'
                  autoCorrect='off' autoCapitalize='off'
                  autoComplete='email'
                  value={editedProf.email} onChange={updateEditedProfHandler('email')}/>
        </td>
      </tr>
    </tbody></table>

  </Fragment>
);

export default NarrowDescription;
