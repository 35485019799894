import { combineReducers } from 'redux';

import * as Types from 'client/Types';
import * as Actions from './Actions';
import { applyChanges } from 'util/ReactUtils';

const modalInitialState : Types.ModalState = {
  loginModalVisibility : Types.ModalVisibility.Initial,
  loginModalUsername: '',
  confirmModalVisibility : Types.ModalVisibility.Initial,
  confirmModalTitle: '',
  confirmModalMessage: '',
  confirmModalAcceptText: '',
  confirmModalCancelText: '',
  confirmModalHandleAccept: () => {},
  confirmModalHandleCancel: () => {}
};

const globalInitialState : Types.GlobalState = {
  nrOfProfessionals : 0,
  nrOfReviews : 0
};

const authInitialState : Types.AuthState = {
  authUser : null
};

const professionalsInitialState : Types.ProfessionalsState = {
  queriedString: '',
  queriedOffset: 0,
  queriedLimit: 0,
  queriedProfIds: [],
  pageIndex: 0,
  pagesTotal: 0,
  resultsTotal: 0,
  pageLinks: {previousPageLink: null, numberedPageLinks: [], nextPageLink: null},
  professionalsById: [],
  lastUpdated : null
};

const reviewsInitialState : Types.ReviewsState = {
  reviewsByRevieweeId: [],
  lastUpdated : null
};

// const notifierInitialState : NotifierState = {
//   notifications: []
// };


const modalReducer : (state : Types.ModalState, action : Actions.ModalAction) => Types.ModalState =
      (state = modalInitialState, action) => {
  switch (action.type) {
    case 'SET_LOGIN_MODAL_VISIBILITY': {
      return applyChanges(state, {
        loginModalVisibility: action.isVisible
      });
    }
    case 'SET_LOGIN_MODAL_USERNAME': {
      return applyChanges(state, {
        loginModalUsername: action.username
      });
    }
    case 'SET_CONFIRM_MODAL_VISIBILITY': {
      return applyChanges(state, {
        confirmModalVisibility: action.isVisible
      });
    }
    case 'INIT_CONFIRM_MODAL': {
      return applyChanges(state, {
        confirmModalTitle: action.title,
        confirmModalMessage: action.message,
        confirmModalAcceptText: action.acceptText,
        confirmModalCancelText: action.cancelText,
        confirmModalHandleAccept: action.handleAccept,
        confirmModalHandleCancel: action.handleCancel,
        confirmModalVisibility: action.isVisible
      });
    }
    default:
      return state;
  }
};

const globalReducer : (state : Types.GlobalState, action : Actions.GlobalAction) => Types.GlobalState =
      (state = globalInitialState, action) => {
  switch (action.type) {
    case 'SET_STATS': {
      return applyChanges(state, {
        nrOfProfessionals : action.nrOfProfessionals,
        nrOfReviews : action.nrOfReviews
      });
    }
    default:
      return state;
  }
};

const authReducer : (state : Types.AuthState, action : Actions.AuthAction) => Types.AuthState =
      (state = authInitialState, action) => {
  switch (action.type) {
    case 'SET_AUTHENTICATED_USER': {
      return applyChanges(state, {
        authUser: action.authUser
      });
    }
    default:
      return state;
  }
};

const professionalsReducer : (state : Types.ProfessionalsState, action : Actions.ProfessionalsAction) => Types.ProfessionalsState =
      (state = professionalsInitialState, action) => {
  switch (action.type) {
    case 'RECEIVE_PROFESSIONALS': {
      const receivedProfsById = action.professionals.map((prof) => ({id: prof.userId, prof: prof}));
      const receivedProfIds = receivedProfsById.map(({id}) => id);
      const newProfsById = state.professionalsById.filter(({id}) => !receivedProfIds.includes(id))
                             .concat(receivedProfsById);
      // console.log('Reducer.RECEIVE_PROFESSIONALS', action.offset, receivedProfIds.length);
      // If the query and nr of results didn't change, we reuse the old ids. Whenever out of range results are shown
      // they will trigger an appropriate query, so any incorrect id's will only be shown briefly, and reduce empty
      // loading pages.
      const newQueriedProfIds =
        ( state.queriedString !== action.queriedString || state.queriedProfIds.length !== action.resultsTotal
        ? new Array(action.resultsTotal).fill(null) // If query or nr of results changed, use fresh array
        : state.queriedProfIds
        ).map((oldId, ix) => // Updated ids in the range that was just queried
          ix >= action.queriedOffset && ix < action.queriedOffset + receivedProfIds.length
          ? receivedProfIds[ix - action.queriedOffset]
          : oldId);
      return applyChanges(state, {
        queriedString: action.queriedString,
        queriedOffset: action.queriedOffset,
        queriedLimit: action.queriedLimit,
        queriedProfIds: newQueriedProfIds,
        pageIndex: action.pageIndex,
        pagesTotal: action.pagesTotal,
        resultsTotal: action.resultsTotal,
        pageLinks: action.pageLinks,
        professionalsById: newProfsById,
        lastUpdated: action.receivedAt
      });
    }
    case 'RECEIVE_PROFESSIONAL': {
      const newProfsById = state.professionalsById.filter(({id}) => id !== action.professional.userId)
        .concat({id: action.professional.userId, prof: action.professional});
      return applyChanges(state, {
        professionalsById: newProfsById, // Just add to cached professionals, don't modify queriedIds
        lastUpdated: action.receivedAt
      });
    }
    case 'REMOVE_PROFESSIONAL': {
      const newProfsById = state.professionalsById.filter(({id}) => id !== action.userId);
      return applyChanges(state, {
        queriedProfIds: newProfsById.map(({id}) => id),
        professionalsById: newProfsById,
      });
    }
    default:
      return state;
  }
};

const reviewsReducer : (state : Types.ReviewsState, action : Actions.ReviewsAction) => Types.ReviewsState =
      (state = reviewsInitialState, action) => {
  switch (action.type) {
    case 'RECEIVE_REVIEWS': {
      return applyChanges(state, {
        reviewsByRevieweeId: state.reviewsByRevieweeId.filter(({id}) => id !== action.userId)
          .concat({id: action.userId, reviews: action.reviews}),
        lastUpdated: action.receivedAt
      });
    }
    case 'REMOVE_REVIEW': {
      const newReviewsByRevieweeId = state.reviewsByRevieweeId.filter(({id}) => id !== action.reviewId);
      return applyChanges(state, {
        reviewsByRevieweeId: newReviewsByRevieweeId,
      });
    }
    default:
      return state;
  }
};

// const notifierReducer : (state : NotifierState, action : Actions.NotifierAction) => NotifierState =
//       (state = notifierInitialState, action) => {
//   switch (action.type) {
//     case 'NOTIFY':  {
//       return applyChanges(state, {
//         notifications: state.notifications.concat(action.notification)
//       });
//     }
//     case 'CLEAR_NOTIFICATIONS': {
//       return applyChanges(state, {
//         notifications: []
//       });
//     }
//     default:
//       return state;
//   }
// };

const rootReducer = combineReducers({
  modal: modalReducer,
  global: globalReducer,
  auth: authReducer,
  professionals: professionalsReducer,
  reviews: reviewsReducer,
  // notifier: notifierReducer,
});

export default rootReducer;
